/** @format */

import { genericActions, wsHandle, wsOut } from "constants/actionTypes";

export const initializeWebsocket = (payload) => ({
	type: wsOut.INIT_WEBSOCKET,
	payload: payload,
	meta: "SEND_SIGNAL",
});

export const requestClientToken = () => ({
	type: wsOut.REQUEST_CLIENT_TOKEN,
	meta: "SEND_SIGNAL",
});

export const requestJoinRoom = (payload) => ({
	type: wsOut.REQUEST_JOIN_ROOM,
	payload: payload,
	meta: "SEND_SIGNAL",
});

export const requestChatHistory = (payload) => ({
	type: wsOut.REQUEST_CHAT_HISTORY,
	payload: payload,
	meta: "SEND_SIGNAL",
});

export const requestPollResults = () => ({
	type: wsOut.REQUEST_POLL_RESULTS,
	meta: "SEND_SIGNAL",
});

export const handleVote = (payload) => ({
	type: wsOut.WANT_TO_VOTE_FOR_POLL,
	payload: payload,
	meta: "SEND_SIGNAL",
});

export const handleClientTokenRequested = (payload) => ({
	type: wsHandle.HANDLE_CLIENT_TOKEN_REQUESTED,
	payload: payload,
});

export const handleReconnected = (payload) => ({
	type: wsHandle.HANDLE_RECONNECTED,
	payload: payload,
});

export const handleAttendeesRequested = (payload) => ({
	type: wsHandle.HANDLE_ATTENDEES_REQUESTED,
	payload: payload,
});

export const handleRoomJoined = (payload) => ({
	type: wsHandle.HANDLE_ROOM_JOINED,
	payload: payload,
});

export const handleMyIncomingVote = (payload) => ({
	type: wsHandle.HANDLE_MY_VOTE,
	payload: payload,
});

export const handleIncomingVote = (payload) => ({
	type: wsHandle.HANDLE_INCOMING_VOTE,
	payload: payload,
});

export const resolveOwnHeldMessages = (payload) => ({
	type: wsHandle.HANDLE_OWN_HELD_MESSAGES,
	payload: payload,
});

export const handleChatHistoryFetching = (payload) => ({
	type: genericActions.SET_FETCHING_HELD,
	payload: payload,
});

export const handleNavigate = (payload) => ({
	type: genericActions.NAVIGATE,
	payload: payload,
});
