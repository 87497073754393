const initialState = [];

const schedulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SCHEDULES::SET_SCHEDULES":
            // Filter schedules that has no slots.
            return action.payload.filter(sc => sc.timeslots.length > 0);

        default:
            return state;
    }
};

export default schedulesReducer;
