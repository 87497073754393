/** @format */

import { getTimeStamp } from "utils/time";

const initialState = {
	event: null,
	channel_name: null,
	chat_user_id: null,
	online: false,
	silenced: false,
	silencedTime: null,
	joined_rooms: [],
	joiningRoom: false,
};

const connectionReducer = (state = initialState, action) => {
	switch (action.type) {
		case "USER::SET_USER":
			return { ...state, chat_user_id: action.payload.chat_user_id };

		case "WEBSOCKET::INIT":
			return { ...state, event: action.payload.event };

		case "WEBSOCKET::HANDLE_SIGNAL::CLIENT_TOKEN_REQUESTED": {
			const { channel_name, is_moderator, user_id } = action.payload;
			return {
				...state,
				channel_name: channel_name,
				chat_user_id: user_id,
				is_moderator: is_moderator,
				online: true,
			};
		}

		case "WEBSOCKET::HANDLE_SIGNAL::RECONNECTED": {
			const { channel_name, is_moderator, user_id, joined_rooms } = action.payload;
			return {
				...state,
				channel_name: channel_name,
				chat_user_id: user_id,
				is_moderator: is_moderator,
				joined_rooms: joined_rooms,
				online: true,
			};
		}

		case "WEBSOCKET::OUT::REQUEST_JOIN_ROOM": {
			return { ...state, joiningRoom: true };
		}
		case "WEBSOCKET::HANDLE_SIGNAL::ROOM_JOINED": {
			const { room_id } = action.payload;
			const newState = { ...state };
			newState.joiningRoom = false;
			if (!newState.joined_rooms.includes(room_id)) {
				newState.joined_rooms.push(room_id);
			}
			return newState;
		}
		case "WEBSOCKET::HANDLE_SIGNAL::ROOM_JOIN_FAILED": {
			return {...state, joiningRoom: false};
		}

		case "WEBSOCKET::HANDLE_SIGNAL::EVENT_CONNECT": {
			return { ...state, online: true };
		}
		case "WEBSOCKET::HANDLE_SIGNAL::EVENT_DISCONNECT": {
			return { ...state, online: false };
		}
		case "WEBSOCKET::SIGNAL::RECONNECTED":
			return { ...state };

		case "WEBSOCKET::CONNECT":
			return { ...state };

		case "WEBSOCKET::DISCONNECT":
			return { ...state, online: false };

		case "WEBSOCKET::HANDLE::USER_SILENCED":
			return { ...state, silenced: true, silencedTime: getTimeStamp(action.payload.time) };

		case "USER::STOP_SILENCE": {
			return { ...state, silenced: false, silencedTime: null };
		}

		case "LOGOUT":
			return initialState;

		default:
			return state;
	}
};

export default connectionReducer;
