/** @format */

export function parseInitials(name) {
	let cleaned = String(name).replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
	const names = cleaned.split(" ");

	if (names.length > 1) {
		let lastName = names[1] === "Guest" ? "" : names[1];
		return names[0].charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
	}
	return names[0].charAt(0).toUpperCase();
}
export const makeSubstring = (word, start, end, limit) => {
	if (word.length <= limit) return word;
	else return `${word.substring(start, end)}...`;
};
