/** @format */

import React from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "views/home/home";
import AppLocalizationProvider from "./localization/localization";
import store from "./store";
import history from "./utils/history";

import UserValidator from "./components/wrappers/userValidator";

import * as paths from "constants/paths";
import User from "views/user/user";
import Chat from "views/module-chat/chat";
import Polls from "views/module-polls/polls";
import Schedules from "views/schedules/schedules";
import PerformerLists from "views/performers/performers";
import Schedule from "views/schedules/schedule";
import Performer from "views/performers/performer";
import Poll from "views/module-polls/poll";
import ViewChatFeed from "views/chatFeed/viewChatFeed";
import Scan from "views/scan/scan";
import Chats from "views/module-chat/chats";
import EventInfo from "views/eventInfo/eventInfo";
import ViewPollResults from "views/pollResults/viewPollResults";
import "scss/root.scss";
import Venuemap from "views/venuemap/venuemap";
import VenueMaps from "views/venuemap/venueMaps";
import { MantineProvider } from "@mantine/core";
import { ChatContextProvider } from "views/module-chat/chatContext";
import PerformerList from "views/performers/performerList";

const App = props => {
	return (
		<MantineProvider>
			<Provider store={store}>
				<Helmet>
					<script>
						{`; (function (p, l, o, w, i, n, g) {
						if (!p[i]) {
							p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || []; p.GlobalSnowplowNamespace.push(i);
							p[i] = function () {(p[i].q = p[i].q || []).push(arguments)}; p[i].q = p[i].q || []; n = l.createElement(o); g = l.getElementsByTagName(o)[0];
							n.async = 1; n.src = w; g.parentNode.insertBefore(n, g)
						}
					}(window, document, "script", 'https://${process.env.REACT_APP_COLLECTOR_DOMAIN}/client.js', "sp"));`}
					</script>
				</Helmet>
				<Router history={history}>
					<Switch>
						<Route path={paths.eventPath(":slug")}>
							<AppLocalizationProvider>
								<UserValidator>
									<Route path={paths.eventPath(":slug")}>
										<Route path={paths.eventPath(":slug")} exact>
											<Home />
										</Route>
										<Route path={paths.eventInfoPath(":slug")} exact>
											<EventInfo />
										</Route>
										<Route path={paths.allChatsPath(":slug")} exact>
											<Chats />
										</Route>

										<Route path={paths.chatPath(":slug", ":room")}>
											<ChatContextProvider>
												<Chat />
											</ChatContextProvider>
										</Route>

										<Route path={paths.pollsPath(":slug")} exact>
											<Polls />
										</Route>

										<Route path={paths.pollPath(":slug", ":id", "/:type?")}>
											<Poll />
										</Route>

										<Route path={paths.schedulePath(":slug")} exact>
											<Schedules />
										</Route>

										<Route path={paths.scheduleSinglePath(":slug", ":id")}>
											<Schedule />
										</Route>

										<Route path={`${paths.performersPath(":slug")}`} exact>
											<PerformerLists />
										</Route>

										<Route path={`${paths.performerListPath(":slug", ":id")}`} exact>
											<PerformerList />
										</Route>

										<Route path={paths.performersSinglePath(":slug", ":performer_id")} exact>
											<Performer />
										</Route>

										<Route path={paths.userPath(":slug")}>
											<User />
										</Route>

										<Route path={paths.chatFeedPath(":slug", ":room")}>
											<ViewChatFeed />
										</Route>

										<Route path={paths.pollResultsPath(":slug", ":uuid")}>
											<ViewPollResults />
										</Route>

										<Route path={paths.venuemapsPath(":slug")} exact>
											<VenueMaps />
										</Route>

										<Route path={paths.currentMapPath(":slug", ":map")}>
											<Venuemap />
										</Route>
									</Route>
								</UserValidator>
							</AppLocalizationProvider>
						</Route>

						<Route path="/" exact>
							<AppLocalizationProvider>
								<Scan />
							</AppLocalizationProvider>
						</Route>
					</Switch>
				</Router>
			</Provider>
		</MantineProvider>
	);
};

export default App;
