
export function parseUniqueTagsFromPerformers(performers) {
    const allTags = [];
    performers.forEach(p => {
        p.tags.forEach(t => allTags.push(t));
    });
    return [...new Set(allTags)].sort();
}

export function findTagsFromPerformersWithMultipleOccurance(performers) {

    const tempTagObject = {};
    const tagArray = [];

    performers.forEach(perf => {
        const { tags = [] } = perf;
        tags.forEach(t => {
            if (tempTagObject[t]) {
                tempTagObject[t] += 1;
            } else {
                tempTagObject[t] = 1;
            }
        });
    })

    for (const [key, value] of Object.entries(tempTagObject)) {
        if (value > 1) {
            tagArray.push(key);

        }
    }

    return tagArray
}

export function filterPerformersWithTags(performers, filter = null, tagList = []) {
    if (filter) {
        if (filter === "uncategorized") {
            return performers.filter(p => p.tags.length === 0);
        }
        return performers.filter(p => p.tags.includes(filter))
    }
    return performers.filter(p => !p.tags.some(t => tagList.includes(t)));
}

export const sortByDate = (list = [], key) => {
    if (key) return list.sort((a,b) => new Date(a[key]) - new Date(b[key]))
    return list.sort((a,b) => new Date(a) - new Date(b))
}