
import { useApiGetWebsocketConfiguration } from "hooks/api/websocket";
import { useParams } from "react-router";

const WebsocketLoader = props => {
    const { children = null } = props;

    const { slug } = useParams();
    useApiGetWebsocketConfiguration(slug);

    return children;

}

export default WebsocketLoader;