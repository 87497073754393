/** @format */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isBetweenTimes, isPast, isUpcoming } from "utils/time";

const pollsTemplate = {
	current: [],
	past: [],
	upcoming: [],
};

export const usePolls = () => {
	let timer = null;

	const { loading, polls: pollData = [] } = useSelector(state => state.polls);
	const [pollList, setPollList] = useState(pollsTemplate);
	const [calculating, setCalculating] = useState(true);

	const polls = pollData.filter(p => p?.show_in?.includes("event_app"));

	function tickTime() {
		timer = setInterval(() => {
			const current = [];
			const past = [];
			const upcoming = [];

			for (let p of polls) {
				if (isBetweenTimes(p.voting_start_time, p.voting_end_time)) {
					p.type = "current";
					current.push(p);
					continue;
				}
				if (isBetweenTimes(p.start_time, p.voting_start_time)) {
					p.type = "upcoming";
					upcoming.push(p);
					continue;
				}
				if (isPast(p.voting_end_time) && p.show_results) {
					p.type = "past";
					past.push(p);
					continue;
				}
			}

			const newPolls = { current, upcoming, past };
			setPollList(newPolls);
			setCalculating(false);
		}, 1000);
	}

	function removeTimer() {
		clearTimeout(timer);
	}

	useEffect(() => {
		// Dont tick time if there is no polls
		if (!loading && polls.length > 0) {
			tickTime();
		}
		return () => removeTimer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, polls]);

	return [loading, calculating, pollList];
};

export const definePollType = poll => {
	if (isBetweenTimes(poll.start_time, poll.voting_end_time)) return "current";
	if (isUpcoming(poll.start_time)) return "upcoming";
	if (isPast(poll.voting_end_time)) return "past";
};
