/** @format */

import { Localized } from "@fluent/react";
import { LivetoLoader } from "components/loader";
import { LvtText } from "components/lvtComponents/components";
import { uniqueId } from "lodash";
import { requestChatHistory } from "middlewares/actions";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { compareDates } from "utils/time";
import Message from "./message";

function checkNextMessage(next, current) {
	let sameDates = compareDates(next.timestamp, current.timestamp);
	let sameUser = next.user_id === current.user_id;
	let sameSenderType = next.sender === current.sender;

	if (sameDates && sameUser && sameSenderType) {
		return true;
	}
	return false;
}

const ChatFeed = props => {
	const dispatch = useDispatch();

	const { bigScreen = false } = props;
	// Refs
	const chatFeedRef = useRef();
	const scrollRef = useRef();

	// STATE
	const [scrollBlocked, setScrollBlocked] = useState(false);

	// Store connections
	const { online, joiningRoom } = useSelector(state => state.connection);
	const {
		chat_history = [],
		room_id,
		more,
		fetching = false,
		fetching_held_messages = false,
	} = useSelector(state => state.chat);

	const { chat_user_id } = useSelector(state => state.user);

	useEffect(() => {
		const hasScrollbar = chatFeedRef.current.scrollHeight > chatFeedRef.current.clientHeight;
		// If scroll has not been blocked, scroll to latest message
		if (scrollRef && !scrollBlocked && hasScrollbar) {
			scrollIntoView(scrollRef.current, { block: "nearest", behavior: "auto" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chat_history.length]);

	function onScroll() {
		const scrollThreshold =
			chatFeedRef.current.scrollHeight - chatFeedRef.current.scrollTop - chatFeedRef.current.clientHeight;

		let blocked = false;
		if (chatFeedRef && scrollThreshold > 150) {
			blocked = true;
		}
		setScrollBlocked(blocked);
	}

	function loadMoreMessages() {
		dispatch(requestChatHistory({ room_id: room_id, skip: chat_history.length }));
	}

	const messages = [];

	if (chat_history) {
		let handledIndexes = [];
		for (let [index] of chat_history.entries()) {
			if (handledIndexes.includes(index)) {
				continue;
			} else {
				const userMessages = [chat_history[index]];
				handledIndexes.push(index);

				let nextIndex = index + 1;

				if (nextIndex < chat_history.length) {
					for (let i = nextIndex; i < chat_history.length; i++) {
						if (!checkNextMessage(chat_history[i], chat_history[index])) {
							break;
						}

						userMessages.push(chat_history[i]);
						handledIndexes.push(i);
					}
				}

				messages.push(
					<Message
						messages={userMessages}
						key={uniqueId()}
						bigScreen={bigScreen}
						chat_history={chat_history}
					/>
				);
			}
		}
	}

	return (
		<>
			<LvtText set="h1" className="lvt-text-h3 chat-heading">
				<Localized id="navigation-chat" />
			</LvtText>
			{!online && (
				<div className={`load-screen${bigScreen ? " big" : ""}`}>
					<LivetoLoader size="50" className={bigScreen ? "loader" : ""} />
					<p className="text">
						<Localized id="chat-connecting" />
					</p>
				</div>
			)}

			{(fetching || joiningRoom || fetching_held_messages) && (
				<div className={`load-screen${bigScreen ? " big" : ""}`}>
					<LivetoLoader size="50" className={bigScreen ? "loader" : ""} />
					<p className="text">
						<Localized id="chat-fetching-messages" />
					</p>
				</div>
			)}
			<div className={`chat-feed${bigScreen ? " big" : ""}`} ref={chatFeedRef} onScroll={onScroll}>
				{more && (
					<div className="fetch-more-messages" onClick={() => loadMoreMessages()}>
						<Localized id="chat-more-messages" />
					</div>
				)}

				{messages.length > 0 ? messages : <NoMessages fetching={fetching} joiningRoom={joiningRoom} />}

				<div id="bottom" ref={scrollRef}></div>
			</div>
		</>
	);
};

const NoMessages = props => {
	const { fetching, joiningRoom } = props;
	if (fetching || joiningRoom) {
		return <div className="no-messages"></div>;
	}
	return (
		<div className="no-messages">
			<LvtText set="h2" className="lvt-text-h4 nomessages-heading">
				<Localized id="chat-nomessages" />
			</LvtText>
			<LvtText set="small" className="nomessages-description">
				<Localized id="chat-start" />
			</LvtText>
		</div>
	);
};

export default ChatFeed;
