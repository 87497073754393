import { useState, useEffect, useRef } from 'react';
import moment from 'moment/moment';
import 'moment-countdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';




export function useSilenceTimer() {
    const dispatch = useDispatch();
    const { silenced, silencedTime } = useSelector(state => state.connection);
    const timer = useRef(null);
    const [timeUntilUnmuted, setTimeUntilUnmuted] = useState({ hours: 0, minutes: 0, seconds: 0 });
    useEffect(() => {
        if (silenced && !timer) {
            timer.current = setInterval(() => {
                setTimeUntilUnmuted(moment().countdown(silencedTime));

                if (moment().utc().local().isAfter(silencedTime)) {
                    clearInterval(timer)
                    dispatch({ type: 'USER::STOP_SILENCE' });
                }
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [silenced])


    return [silenced, timeUntilUnmuted]
}
