const initialState = {};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case "EVENT::SET_ACTIVE_EVENT":
            return { ...action.payload };
        default:
            return state;
    }
};

export default eventReducer;
