/** @format */

const initialState = null;

const activeEventReducer = (state = initialState, action) => {
	switch (action.type) {
		case "EVENT::SET_ACTIVE_EVENT":
			return action.payload.slug;

		case "EVENT::EXIT":
			return null;

		default:
			return state;
	}
};

export default activeEventReducer;
