/** @format */

import React, { useEffect, useState } from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useDispatch } from "react-redux";
import { LvtText } from "components/lvtComponents/components";
import { pollPath } from "constants/paths";
import { useParams } from "react-router";
import { LvtLink } from "components/lvtComponents/components";
import { Localized } from "@fluent/react";
import { usePolls } from "hooks/polls";
import moment from "moment";
import LoadingScreen from "components/loadingScreen";

import "./polls.scss";

const Polls = props => {
	const dispatch = useDispatch();
	const [loading, calculating, polls] = usePolls();

	const { current = [], upcoming = [], past = [] } = polls;

	useEffect(() => {
		dispatch({ type: "WEBSOCKET::OUT::REQUEST_POLL_RESULTS", meta: "SEND_SIGNAL" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NavigationWrapper className="polls">
			<LvtText set="h1" className="lvt-text-h3 polls-heading">
				<Localized id="navigation-polls" />
			</LvtText>
			{loading || calculating ? (
				<LoadingScreen />
			) : (
				<>
					<LvtText set="h2" className="lvt-text-h4 polls-heading">
						<Localized id="polls-heading-ongoing" />
					</LvtText>
					{current.length > 0 ? (
						current.map(p => <PollCurrent {...p} key={p.id} />)
					) : (
						<LvtText set="p" className="no-polls-listed">
							<Localized id="polls-noongoing" />
						</LvtText>
					)}

					<LvtText set="h2" className="lvt-text-h4 polls-heading">
						<Localized id="polls-heading-upcoming" />
					</LvtText>
					{upcoming.length > 0 ? (
						upcoming.map(p => <PollUpcoming {...p} key={p.id} />)
					) : (
						<LvtText set="p" className="no-polls-listed">
							<Localized id="polls-noupcoming" />
						</LvtText>
					)}

					<LvtText set="h2" className="lvt-text-h4 polls-heading">
						<Localized id="polls-heading-past" />
					</LvtText>
					{past.length > 0 ? (
						past.map(p => <PollPast {...p} key={p.id} />)
					) : (
						<LvtText set="p" className="no-polls-listed">
							<Localized id="polls-nopast" />
						</LvtText>
					)}
				</>
			)}
		</NavigationWrapper>
	);
};

function timeLeft(time) {
	if (time.days > 0 || time.years > 0 || time.months > 0) {
		// Calculate how many days from years, months, and dates
		return <Localized id="time-left-days" vars={{ days: time.days }} />;
	}
	if (time.hours > 0) {
		return <Localized id="time-left-hours" vars={{ hours: time.hours }} />;
	}
	const timeleft = `${time.minutes}:${time.seconds}`;
	return <Localized id="time-left-close" vars={{ text: timeleft }} />;
}

function timeTo(time) {
	if (time.days > 0 || time.years > 0 || time.months > 0) {
		// Calculate how many days from years, months, and dates
		return <Localized id="time-to-days" vars={{ days: time.days }} />;
	}
	if (time.hours > 0) {
		return <Localized id="time-to-hours" vars={{ hours: time.hours }} />;
	}
	const timeto = `${parseInt(time.minutes).toLocaleString("fi-FI", { minimumIntegerDigits: 2 })}:${parseInt(
		time.seconds
	).toLocaleString("fi-Fi", { minimumIntegerDigits: 2 })}`;
	return <Localized id="time-to-close" vars={{ text: timeto }} />;
}

const useCountdown = targetDate => {
	const [countdown, setCountdown] = useState(0);

	useEffect(() => {
		const calculateCountdown = () => {
			const now = moment();
			const target = moment(targetDate);
			const diff = target.diff(now);
			setCountdown(diff);
		};

		// Initial calculation
		calculateCountdown();

		// Update every second
		const interval = setInterval(() => {
			calculateCountdown();
		}, 1000);

		// Clear interval on component unmount
		return () => clearInterval(interval);
	}, [targetDate]);

	return getReturnValues(countdown);
};

const getReturnValues = countDown => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = new Date(countDown).getUTCSeconds();

	return { days, hours, minutes, seconds };
};

const PollCurrent = props => {
	const { voting_end_time, question, id, type } = props;
	const { slug } = useParams();
	const time = useCountdown(voting_end_time);

	return (
		<LvtLink to={pollPath(slug, id, `/${type}`)} className={`poll current`}>
			<LvtText set="h2" className="question">
				{question}
			</LvtText>
			{timeLeft(time)}
		</LvtLink>
	);
};

const PollUpcoming = props => {
	const { voting_start_time, question } = props;
	const time = useCountdown(voting_start_time);

	return (
		<div className={`poll upcoming`}>
			<LvtText set="h3">{question}</LvtText>
			<LvtText set="p" className="time">
				{timeTo(time)}
			</LvtText>
		</div>
	);
};

const PollPast = props => {
	const { question, id, type } = props;
	const { slug } = useParams();

	return (
		<LvtLink to={pollPath(slug, id, `/${type}`)} className={`poll past`}>
			<LvtText set="p">{question}</LvtText>
			<LvtText set="small" className="time">
				<Localized id="poll-past-checkresults" />
			</LvtText>
		</LvtLink>
	);
};

export default Polls;
