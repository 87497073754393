/** @format */

import React, { useState } from "react";

import { LvtBtn, LvtText, LvtCol } from "components/lvtComponents/components";
import { useApiPostGuestLogin } from "hooks/api/login";
import { useSelector } from "react-redux";

import "./authentication.scss";
import { LvtRow } from "components/lvtComponents/components";
import { Localized } from "@fluent/react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";


const Authentication = (props) => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const {
    name,
    event_main_image = null,
    languages = [],
  } = useSelector((state) => state.event);
  const { language } = useSelector((state) => state.user);

  const [username, setUsername] = useState("");

  const [redirect, setRedirect] = useState(false);

  const { loginGuest } = useApiPostGuestLogin();
  function authGuest() {
    loginGuest({ username: username, event: slug });
  }

  function exit() {
    dispatch({ type: "EVENT::EXIT" });
    setRedirect(true);
  }

  function changeLang(lang) {
    dispatch({ type: "USER::CHANGE_LANGUAGE", payload: lang });
  }

  if (redirect) {
    return <Redirect to={"/"} />;
  }


  return (
    <>
      <div id="authentication">
        <div className="banner">
          <div
            className="event-image"
            style={{ backgroundImage: `url("${event_main_image}")` }}
          />

          {languages.map((lang) => (
            <button
              className={`language-button ${lang}${
                language === lang ? " active" : ""
              }`}
              onClick={() => changeLang(lang)}
            >
              <Localized id={`auth-language-button-${lang}`} />
            </button>
          ))}
        </div>

        <LvtCol justify="center" align="center" className="auth">
          <LvtText set="h1" className="lvt-text-h4 event-name">
            {name}
          </LvtText>
          <LvtText set="small" className="instructions">
            <Localized id="auth-guest-instructions" />
          </LvtText>
          <p className="auth-type-text">
            <Localized id="auth-guest-heading" />
          </p>
          <LvtRow>
            <Localized id="auth-guest-input" attrs={{ placeholder: true }}>
              <input
                className="guest-input"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Localized>
            <button className="guest-auth-btn" onClick={() => authGuest()}>
              <Localized id="auth-guest-button" />
            </button>
          </LvtRow>

          <LvtRow justify="center" align="center">
            <LvtBtn set="link" className="exit" onClick={exit}>
              <Localized id="auth-exit" />
            </LvtBtn>
          </LvtRow>
        </LvtCol>
      </div>
    </>
  );
};

export default Authentication;
