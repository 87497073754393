/** @format */

import { useDispatch } from "react-redux";
import { setCookie } from "utils/cookies";
import { useApiPost } from "./generic";

export function useApiPostRegisteredLogin() {
	const endpoint = `/api/login/`;

	const [post, state, resetState] = useApiPost({ apipath: endpoint });

	// Always refresh page when logging in, use redirect conditionally for example to events.liveto.io
	async function login(body, redirect = null) {
		const [data, ok] = await post(endpoint, body);
		if (ok) {

			setCookie(process.env.REACT_APP_AUTH_COOKIE, data.token, 1);

			if (redirect) {
				window.location.replace(redirect);
			} else {
				window.location = window.location.href;
			}
		}
	}
	return { login, loginState: state, resetLoginState: resetState };
}

export function useApiPostGuestLogin() {
	const dispatch = useDispatch();
	const endpoint = `/api/login/guest/`;
	const [post, state, resetState] = useApiPost({ apipath: endpoint });

	// Always refresh page when logging in, use redirect conditionally for example to events.liveto.io
	async function loginGuest(body, redirect = null) {
		const [data, ok] = await post(endpoint, body);
		if (ok) {
			dispatch({
				type: "USER::GUEST_LOGIN",
				payload: { authType: "guest", chatUserId: data.chat_user_id },
			});
			setCookie(process.env.REACT_APP_AUTH_COOKIE, data.token, 1);
			window.location = window.location.href;
		}
	}
	return {
		loginGuest,
		guestLoginState: state,
		resetGuestLoginState: resetState,
	};
}
