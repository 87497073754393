import { initializeWebsocket } from 'middlewares/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useApiGet } from './generic';


export function useApiGetWebsocketConfiguration(slug) {
    const apipath = '/api/v1/chat/';
    const dispatch = useDispatch();
    const [get, state, resetState] = useApiGet({ apipath });


    useEffect(() => {
        getWebsocketConfiguration();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getWebsocketConfiguration() {
        const [data, ok] = await get(`${apipath}${slug}.json`)
        if (ok) {
            dispatch(initializeWebsocket(data));
        }

    }
    return { getWebsocketConfiguration, getWebsocketStatus: state, resetWebsocketStatus: resetState };
}