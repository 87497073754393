import { handleChatHistoryFetching, resolveOwnHeldMessages } from "middlewares/actions"
import { chatServiceInvoke } from "middlewares/websocket"

export const getUserHeldMessages = async (room_id, user_id, dispatch) => {
    try {
        dispatch(handleChatHistoryFetching(true))
        const response = await chatServiceInvoke('getHeldMessagesForUser', { userID: user_id, roomID: room_id })
        if (response.length > 0) {
            const held = response.map(msg => {
                const obj = msg.payload
                obj.held = true
                obj.timestamp = obj.timestamp_recv
                return obj
            })
            dispatch(resolveOwnHeldMessages({ held_messages: held, room_id: room_id }))
        }
    } catch (error) {
        console.error("Error fetching own held messages", error)
    } finally {
        dispatch(handleChatHistoryFetching(false))
    }
}