/** @format */

import React from "react";
import { useSelector } from "react-redux";
import { LvtRow } from "../lvtComponents/components";
import "./header.scss";
import { useLocalization } from "@fluent/react";

const Header = props => {
	const { name = "Event App" } = useSelector(state => state.event);
	const { l10n } = useLocalization();
	return (
		<LvtRow className="header" justify="between">
			<span className="title">{name}</span>
			<img alt={l10n.getString('alt-brand-logo')} className="brand-logo" />
		</LvtRow>
	);
};

export default Header;
