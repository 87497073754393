import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import 'scss/components/liveto-components.scss';

export const LvtLink = props => {
    const { search } = useLocation();
    const {
        children = null,
        set = "link",
        variant = "md",
        className: classNames = "",
        to = "",
        ...rest
    } = props;
    return <NavLink to={to + search} className={`lvt-link-${set} ${variant}${classNames ? ` ${classNames}` : ''}`} {...rest}>
        {children}
    </NavLink>
}

export const LvtText = props => {
    const { children, set = "p", className: classNames = "", ...rest } = props;
    const className = `lvt-text-${set} ${classNames ? ` ${classNames}` : ''} `;
    const textProps = { className, ...rest };
    switch (set) {
        case 'p': return <p {...textProps}>{children}</p>
        case 'small': return <small {...textProps}>{children}</small>
        case 'strong': return <strong {...textProps}>{children}</strong>
        case 'h1': return <h1 {...textProps}>{children}</h1>;
        case 'h2': return <h2 {...textProps}>{children}</h2>;
        case 'h3': return <h3 {...textProps}>{children}</h3>;
        case 'h4': return <h4 {...textProps}>{children}</h4>;
        case 'h5': return <h5 {...textProps}>{children}</h5>;
        case 'h6': return <h6 {...textProps}>{children}</h6>;
        default: return <p {...textProps}>{children}</p>
    }
}

export const LvtBtn = props => {
    const { children, variant = "md", set = "primary", className: classNames = "", ...rest } = props;
    return <button className={`lvt-btn-${set} ${variant} ${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children}
    </button>
}

export const LvtCard = props => {
    const { className: classNames = "", children, ...rest } = props;

    return <div className={`lvt-card${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children || null}
    </div >
}

export const LvtCardHead = props => {
    const { className: classNames = "", children, ...rest } = props;
    return <div className={`lvt-card-head${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children || null}
    </div>
}

export const LvtCardBody = props => {
    const { className: classNames = "", children, ...rest } = props;
    return <div className={`lvt-card-body${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children || null}
    </div>
}
export const LvtCardFoot = props => {
    const { className: classNames = "", children, justify = "start", ...rest } = props;
    return <div className={`lvt-card-foot lvt-justify-${justify} ${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children || null}
    </div>
}


export const LvtRow = props => {
    const { className: classNames = "", justify = "start", align = "center", children, ...rest } = props;
    return <div className={`lvt-row justify-${justify} align-${align}${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children || null}
    </div >
}



export const LvtCol = props => {
    const { className: classNames = "", justify = "start", align = "start", children, ...rest } = props;

    return <div className={`lvt-col justify-${justify} align-${align}${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children || null}
    </div >
}

export const LvtTextInput = props => {
    const { children, className: classNames = "", ...rest } = props;
    return <input type="text" className={`lvt-input-text${classNames ? ` ${classNames}` : ''} `} {...rest} />
}


export const LvtSelect = props => {
    const { children = null, className: classNames = "", ...rest } = props;
    return <select className={`lvt-input-select${classNames ? ` ${classNames}` : ''} `} {...rest}>
        {children}
    </select>
}
