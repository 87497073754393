
import React from 'react';
import {
    PieChart,
    Pie,
    Cell,
} from 'recharts';

const Chart = props => {
    const { options, width, height } = props;


    return <>
        <PieChart width={width} height={height / 100 * 60} className="piechart">
            <Pie data={options}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={width / 5}
                min={10}
            >
                {options.map((entry, index) => (<Cell key={`cell - ${index} `} fill={entry.color}>
                </Cell>))}
            </Pie>
        </PieChart>
    </>
}

export default Chart;