/** @format */

import React, { Children, useEffect, useState } from "react";

import { negotiateLanguages } from "@fluent/langneg";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import { ReactLocalization, LocalizationProvider } from "@fluent/react";
import { useSelector } from "react-redux";
import { LivetoLoader } from "components/loader";
import "./localization.scss";
import { useParams } from "react-router";
const DEFAULT_LOCALE = "en";
const AVAILABLE_LOCALES = {
	en: "English",
	fi: "Finnish",
	se: "Swedish"
};



function* lazilyParsedBundles(fetchedMessages) {
	for (let [locale, messages] of fetchedMessages) {
		let resource = new FluentResource(messages);
		let bundle = new FluentBundle(locale);
		bundle.addResource(resource);
		yield bundle;
	}
}

function AppLocalizationProvider(props) {
	const { slug = null } = useParams();
	const { language } = useSelector(state => state.user);

	let [l10n, setL10n] = useState(null);

	useEffect(() => {
		changeLocales([language]);
	}, [language]);

	async function fetchMessages(locale) {
		let messages = "";
		if (slug) {
			let custom = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/events/${slug}/event_app/custom.${locale}.ftl`).then(resp => resp.text()).catch(err => "");
			messages += custom;
			messages += `\n`;
		}
		let response = await fetch(`${process.env.PUBLIC_URL}/locales/${locale}-locale.ftl`, {
			// Test if this stops the localization caching if the locales has changed
			headers: {
				"pragma": "no-cache",
				"cache-control": "no-cache"
			}
		});
		let text = await response.text();
		messages += text;
		messages += "\n";

		return [locale, messages];
	}


	async function changeLocales(userLocales) {
		let currentLocales = negotiateLanguages(userLocales, Object.keys(AVAILABLE_LOCALES), {
			defaultLocale: DEFAULT_LOCALE,
		});
		let fetchedMessages = await Promise.all(currentLocales.map(fetchMessages));
		let bundles = lazilyParsedBundles(fetchedMessages);
		setL10n(new ReactLocalization(bundles));
	}

	if (l10n === null) {
		// TODO Proper view for this
		return (
			<div className="localization-loading">
				<LivetoLoader size={100} />
				Loading localization
			</div>
		);
	}

	return <LocalizationProvider l10n={l10n}>{Children.only(props.children)}</LocalizationProvider>;
}

export default AppLocalizationProvider;
