import createHistory from "history/createBrowserHistory";
import store from "../store/index";
import { handleNavigate } from "middlewares/actions";

const history = createHistory();

history.listen((location) => {
    store.dispatch(handleNavigate(location));
});

export default history;
