/** @format */

import React from "react";

import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import "./eventInfo.scss";
import { useLocalization } from "@fluent/react";

const EventInfo = props => {
	const { description = {}, image, video } = useSelector(state => state.config);
	const { language } = useSelector(state => state.user);
	const { l10n } = useLocalization();
	const currentDescription = description[language] || "";
	return <NavigationWrapper className="event-info">
		{image && <img src={image} className="image" alt={l10n.getString('alt-event-info-banner-image')} />}
		<div className="description">
			{parse(currentDescription)}
		</div>
		{video && <div className="video-container" >
			<iframe src={video} className="video" title="video" />
		</div>}
	</NavigationWrapper>;
};

export default EventInfo;
