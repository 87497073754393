/** @format */

import { IconLock } from "components/icons";
import { LvtBtn, LvtLink, LvtText } from "components/lvtComponents/components";
import { useSelector } from "react-redux";
import { allChatsPath } from "constants/paths";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router";
import { Localized } from "@fluent/react";
import { IconArrowLeft } from "components/icons";

const PasswordPrompt = () => {
	const { room, slug } = useParams();
	const { incorrectPassword = false } = useSelector(state => state.chat);
	const dispatch = useDispatch();

	const [pass, setPass] = useState("");

	function onChange(value) {
		setPass(value);
		if (incorrectPassword) {
			dispatch({ type: "CLEAR_INCORRECT_PASSWORD" });
		}
	}
	function join() {
		dispatch({
			type: "WEBSOCKET::OUT::REQUEST_JOIN_ROOM",
			payload: {
				room_id: room,
				password: pass,
			},
			meta: "SEND_SIGNAL",
		});
		setPass("");
	}
	return (
		<div className="passwordPrompt">
			<LvtText set="h1" className="lvt-text-h3 chat-heading">
				<Localized id="navigation-chat" />
			</LvtText>
			<LvtLink to={allChatsPath(slug)} className="back">
				<IconArrowLeft />
				<span>
					<Localized id="chat-passwordprompt-back" />
				</span>
			</LvtLink>

			<IconLock size="100" />
			<LvtText set="h2">
				<Localized id="chat-passwordprompt-protected" />
			</LvtText>
			<Localized id="chat-passwordprompt-input-password" attrs={{ placeholder: true }}>
				<input
					className="password-input"
					placeholder="syötä salasana"
					value={pass}
					onChange={e => onChange(e.target.value)}
				/>
			</Localized>

			{incorrectPassword && (
				<span className="error">
					<Localized id="chat-passwordprompt-incorrectpassword" />
				</span>
			)}
			<button className="submit" onClick={join}>
				<Localized id="chat-passwordprompt-join" />
			</button>
		</div>
	);
};

export default PasswordPrompt;
