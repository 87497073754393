const initialState = {};


const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CONFIG::SET_CONFIG": {
            return action.payload;
        }
        default: return state;
    }
}

export default configReducer