/** @format */

import { IconBarChart } from "components/icons";
import { IconComments } from "components/icons";
import { IconHome } from "components/icons";
import { LvtText } from "components/lvtComponents/components";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as paths from "constants/paths";

import "scss/components/navigationWrapper.scss";
import { LvtRow } from "../lvtComponents/components";
import { parseInitials } from "utils/strings";

import Header from "components/header/header";
import { Localized } from "@fluent/react";
import { LvtLink } from "components/lvtComponents/components";
import { IconStar } from "components/icons";
import { IconCalendar } from "components/icons";
import AppLoading from "components/appLoading/appLoading";

const NavigationWrapper = React.forwardRef((props, ref) => {
	const { children = null, className = "", loading = false } = props;
	if (loading) return <AppLoading />;
	return (
		<div id="navigation-wrapper" ref={ref}>
			<Header />

			<div className={`content ${className}`}>{children}</div>

			<Navigation />
		</div>
	);
});

const Navigation = props => {
	const { slug } = useParams();
	const { first_name, last_name, image = null } = useSelector(state => state.user);
	const polls = useSelector(state => state.polls.polls);
	const performers = useSelector(state => state.performers);
	const chatrooms = useSelector(state => state.chatrooms);
	const schedules = useSelector(state => state.schedules);
	const name = `${first_name} ${last_name}`;

	function checkDisabled(e, content) {
		if (!content) {
			e.preventDefault();
		}
	}
	return (
		<LvtRow className="nav" justify="between">
			<LvtLink to={paths.eventPath(slug)} className="nav-item" exact>
				<IconHome size="28" />
				<LvtText set="small">
					<Localized id="navigation-home" />
				</LvtText>
			</LvtLink>

			{chatrooms.length > 0 && (
				<LvtLink to={paths.allChatsPath(slug)} className="nav-item">
					<IconComments size="28" />
					<LvtText set="small">
						<Localized id="navigation-chat" />
					</LvtText>
				</LvtLink>
			)}

			{polls.length > 0 && (
				<LvtLink to={paths.pollsPath(slug)} className="nav-item">
					<IconBarChart size="28" />
					<LvtText set="small">
						<Localized id="navigation-polls" />
					</LvtText>
				</LvtLink>
			)}

			{schedules.length > 0 && (
				<LvtLink to={paths.schedulePath(slug)} className="nav-item">
					<IconCalendar size="28" />
					<LvtText set="small">
						<Localized id="navigation-schedule" />
					</LvtText>
				</LvtLink>
			)}

			{performers.length > 0 && (
				<LvtLink to={paths.performersPath(slug)} className="nav-item">
					<IconStar size="28" />
					<LvtText set="small">
						<Localized id="navigation-performers" />
					</LvtText>
				</LvtLink>
			)}

			<LvtLink
				to={paths.userPath(slug)}
				className="user-item"
				style={image ? { backgroundImage: `url("${image}")` } : {}}
			>
				{!image && parseInitials(name)}
			</LvtLink>
		</LvtRow>
	);
};

export default NavigationWrapper;
