/** @format */

import { unionBy } from "lodash";
import { sortByDate } from "utils/arrays";

const initialState = {
	fetching: false,
	fetching_held_messages: false,
	incorrectPassword: false,
};

const activeChatReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ACTIVE_CHAT::SET_ACTIVE_ROOM": {
			return action.payload;
		}

		case "WEBSOCKET::HANDLE_SIGNAL::ROOM_JOINED": {
			return action.payload;
		}

		case "WEBSOCKET::OUT::REQUEST_CHAT_HISTORY": {
			return { ...state, fetching: true };
		}

		case "GENERIC::CHAT_HISTORY::FETCHING_HELD": {
			return { ...state, fetching: action.payload };
		}

		case "CLEAR_INCORRECT_PASSWORD": {
			return { ...state, incorrectPassword: false };
		}

		case "WEBSOCKET::HANDLE_SIGNAL::CHAT_HISTORY_REQUESTED": {
			const { skip, room_id, history, more } = action.payload;
			let newState = { ...state };
			if (newState.room_id === room_id) {
				if (skip > 0) {
					let updated_history = history;
					for (let msg of newState["chat_history"]) {
						updated_history.push(msg);
					}
					newState["chat_history"] = updated_history;
				} else {
					newState["chat_history"] = history;
				}
				newState["more"] = more;
				newState["fetching_more_history"] = false;
				newState["fetching"] = false;
			}
			return newState;
		}

		case "WEBSOCKET::HANDLE_SIGNAL::MESSAGE_SENT": {
			let newState = { ...state };
			if (newState.room_id === action.payload.room_id) {
				newState.chat_history.push(action.payload);
			}
			return newState;
		}

		case "WEBSOCKET::HANDLE::MESSAGE_DELETED": {
			let newState = { ...state };
			const { chat_history = [], room_id = "" } = newState;

			const { payload } = action;

			if (room_id === payload.room_id) {
				newState["chat_history"] = chat_history.filter(msg => msg.message_id !== payload.message_id);
			}
			return newState;
		}

		case "WEBSOCKET::HANDLE::ALL_USER_MESSAGES_DELETED": {
			let newState = { ...state };
			const { chat_history = [], room_id = "" } = newState;
			const { payload } = action;

			if (room_id === payload.room_id) {
				newState["chat_history"] = chat_history.filter(msg => msg.user_id !== payload.user_id);
			}
			return newState;
		}

		case "WEBSOCKET::HANDLE_SIGNAL::OWN_HELD_MESSAGES": {
			const { held_messages } = action.payload;
			let newState = { ...state };
			const historyWithHeldMessages = sortByDate(
				unionBy(newState.chat_history, held_messages, "message_id"),
				"timestamp"
			);
			newState.chat_history = historyWithHeldMessages;
			return newState;
		}

		case "WEBSOCKET::HANDLE_SIGNAL::MESSAGE_REACTION": {
			const { amount, message_id, reaction, room_id } = action.payload;
			let newState = { ...state };
			if (newState.room_id === room_id) {
				const msgIndex = newState.chat_history.findIndex(msg => msg.message_id === message_id);
				if (msgIndex !== -1) {
					const { reactions = {} } = newState.chat_history[msgIndex];
					let currentReactions = Object.keys(reactions);

					if (currentReactions.includes(reaction)) {
						newState.chat_history[msgIndex].reactions[reaction] += amount;
					} else {
						newState.chat_history[msgIndex].reactions[reaction] = amount;
					}
				}
			}
			return newState;
		}

		case "WEBSOCKET::HANDLE_SIGNAL::ROOM_JOIN_FAILED": {
			const { error = "", room_id } = action.payload;
			if (error === "invalid_password") {
				return { ...state, incorrectPassword: true };
			}
			return { ...state };
		}

		default:
			return state;
	}
};

export default activeChatReducer;
