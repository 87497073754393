import React from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { LvtBtn } from "components/lvtComponents/components";
import { LvtText } from "components/lvtComponents/components";


import './user.scss';
import { IconSignOut } from "components/icons";
import { setCookie } from "utils/cookies";
import { eventPath } from "constants/paths";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { LvtRow, LvtCol } from "components/lvtComponents/components";
import { useSelector } from "react-redux";
import { Localized } from "@fluent/react";

const User = props => {
    const { slug } = useParams();
    const { language } = useSelector(state => state.user);
    const { languages = [] } = useSelector(state => state.event);
    const dispatch = useDispatch();
    function logout() {
        setCookie(process.env.REACT_APP_AUTH_COOKIE, '', -1);
        dispatch({ type: 'LOGOUT' });
        sessionStorage.removeItem("visited")
        window.location.href = eventPath(slug);
    }

    function changeLanguage(e) {
        dispatch({ type: 'USER::CHANGE_LANGUAGE', payload: e.target.value });
    }

    return <NavigationWrapper className="user">
        <LvtRow>
            <LvtText set="h3" className="user-heading">
                <Localized id="user-heading" />
            </LvtText>
        </LvtRow>
        <LvtCol justify="start" className="settings">
            <LvtRow justify="between" className="setting-row">
                <LvtText set="p" className="user-text">
                    <Localized id="user-language" />
                </LvtText>
                <select className="lvt-input-text language" value={language} onChange={changeLanguage}>
                    {languages.map(l => <Localized id={`user-language-${l}`} key={`lang-${l}`}>
                        <option value={l}>{l}</option>
                    </Localized>)}
                </select>
            </LvtRow>
        </LvtCol>
        <LvtRow justify="end">
            <LvtBtn set="primary" className="logout-button" onClick={() => logout()}>
                <Localized id="user-logout" />
                <IconSignOut size="22" />
            </LvtBtn>
        </LvtRow>
    </NavigationWrapper>
}

export default User;