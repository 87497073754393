/** @format */

export const wsOut = {
	INIT_WEBSOCKET: "WEBSOCKET::INIT",
	REQUEST_CLIENT_TOKEN: "WEBSOCKET::OUT::REQUEST_CLIENT_TOKEN",
	RECONNECT: "WEBSOCKET::OUT::RECONNECT",
	REQUEST_JOIN_ROOM: "WEBSOCKET::OUT::REQUEST_JOIN_ROOM",
	REQUEST_CHAT_HISTORY: "WEBSOCKET::OUT::REQUEST_CHAT_HISTORY",
	SEND_MESSAGE: "WEBSOCKET::OUT::SEND_MESSAGE",
	SEND_PRIVATE_MESSAGE: "WEBSOCKET::OUT::SEND_PRIVATE_MESSAGE",
	REQUEST_ATTENDEES: "WEBSOCKET::OUT::REQUEST_ATTENDEES",
	REACT_TO_MESSAGE: "WEBSOCKET::OUT::REACT_TO_MESSAGE",
	WANT_TO_VOTE_FOR_POLL: "WEBSOCKET::OUT::WANT_TO_VOTE_FOR_POLL",
	REQUEST_POLL_RESULTS: "WEBSOCKET::OUT::REQUEST_POLL_RESULTS",
	DELETE_MESSAGE: "WEBSOCKET::OUT::DELETE_MESSAGE",
	DELETE_ALL_USER_MESSAGES: "WEBSOCKET::OUT::DELETE_ALL_USER_MESSAGES",
	SILENCE_USER: "WEBSOCKET::OUT::WS_SILENCE_USER",
};

export const wsIn = {
	CLIENT_TOKEN_REQUESTED: "WEBSOCKET::INCOMING_SIGNAL::CLIENT_TOKEN_REQUESTED",
	RECONNECTED: "WEBSOCKET::INCOMING_SIGNAL::RECONNECTED",
	RECONNECT_FAILED: "WEBSOCKET::INCOMING_SIGNAL::RECONNECT_FAILED",
	ATTENDEES_REQUESTED: "WEBSOCKET::INCOMING_SIGNAL::ATTENDEES_REQUESTED",
	ROOM_JOINED: "WEBSOCKET::INCOMING_SIGNAL::ROOM_JOINED",
	INCOMING_VOTE: "WEBSOCKET::INCOMING_SIGNAL::VOTE_FOR_POLL",
	MY_VOTE: "WEBSOCKET::INCOMING_SIGNAL::POLL_VOTED",
	MESSAGE_HELD: "WEBSOCKET::INCOMING_SIGNAL::MSG_HELD",
	HELD_MESSAGE_APPROVED: "WEBSOCKET::INCOMING_SIGNAL::MSG_HELD_SENT",
	HELD_MESSAGE_DECLINED: "WEBSOCKET::INCOMING_SIGNAL::MSG_HELD_DELETED"
};

export const wsHandle = {
	HANDLE_CLIENT_TOKEN_REQUESTED: "WEBSOCKET::HANDLE_SIGNAL::CLIENT_TOKEN_REQUESTED",
	HANDLE_RECONNECTED: "WEBSOCKET::HANDLE_SIGNAL::RECONNECTED",
	HANDLE_ROOM_JOINED: "WEBSOCKET::HANDLE_SIGNAL::ROOM_JOINED",
	HANDLE_ATTENDEES_REQUESTED: "WEBSOCKET::INCOMING_SIGNAL::ATTENDEES_REQUESTED",
	HANDLE_INCOMING_VOTE: "WEBSOCKET::HANDLE_SIGNAL::INCOMING_VOTE",
	HANDLE_MY_VOTE: "WEBSOCKET::HANDLE_SIGNAL::MY_VOTE",
	HANDLE_OWN_HELD_MESSAGES: "WEBSOCKET::HANDLE_SIGNAL::OWN_HELD_MESSAGES",
};

export const genericActions = {
	SET_FETCHING_HELD: "GENERIC::CHAT_HISTORY::FETCHING_HELD",
	NAVIGATE: "GENERIC::NAVIGATE",
};