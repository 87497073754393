const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
const USE_SECURE_COOKIE = process.env.REACT_APP_USE_SECURE_COOKIE;

export const setCookie = (name, value, days) => {
    let expires = "";
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
    const cookieDomain = COOKIE_DOMAIN !== "localhost" ? `domain=${COOKIE_DOMAIN};` : null;
    const secureCookie = USE_SECURE_COOKIE ? "secure;" : "";

    document.cookie = `${name}=${value};${expires}; path=/;${cookieDomain}${secureCookie}SameSite=Lax`;
};

export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
