/** @format */

import React from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useParams, Redirect } from "react-router";
import { useSelector } from "react-redux";
import { chatPath } from "constants/paths";
import { LvtLink, LvtCol, LvtText } from "components/lvtComponents/components";
import { Localized } from "@fluent/react";
import { uniqueId } from "lodash";

import "./chat.scss";


const Chats = props => {
	const { slug } = useParams();
	const chatrooms = useSelector(state => state.chatrooms);

	const liveRooms = chatrooms.filter(c => c.live_room);

	if (liveRooms.length === 1) {
		return <Redirect to={chatPath(slug, liveRooms[0].room_id)} />;
	}

	return (
		<NavigationWrapper className="chat">
			<LvtCol className="chatrooms">
				<LvtText set="h3" className="chatlist-heading">
					<Localized id="chats-heading" />
				</LvtText>

				{liveRooms.map(room => (
					<Room {...room} key={uniqueId()} />
				))}
			</LvtCol>
		</NavigationWrapper>
	);
};

const Room = props => {
	const { room_id, room_name, topic } = props;
	const { slug } = useParams();

	return (
		<LvtLink to={chatPath(slug, room_id)} className="room-link">
			<LvtText set="h3" className="room-link-head">{room_name}</LvtText>
			<LvtText set="small" className="room-link-topic">{topic}</LvtText>
		</LvtLink>
	);
};

export default Chats;
