/** @format */

import React from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import * as paths from "constants/paths";
import { IconInfo, IconMap } from "components/icons";
import { IconCalendar } from "components/icons";
import { IconComments } from "components/icons";
import { IconBarChart } from "components/icons";
import { useParams } from "react-router-dom";
import { Localized } from "@fluent/react";
import { IconStar } from "components/icons";
import { LvtLink, LvtText } from "components/lvtComponents/components";
import { useSelector } from "react-redux";
import "./home.scss";

const Home = props => {
	const { slug } = useParams();
	const rooms = useSelector(state => state.chatrooms);
	const polls = useSelector(state => state.polls.polls);
	const performers = useSelector(state => state.performers);
	const schedules = useSelector(state => state.schedules);

	const { maps = [] } = useSelector(state => state.config);

	function checkDisabled(e, hasContent) {
		if (!hasContent) {
			e.preventDefault();
		}
	}

	const hasMaps = maps.length > 0;
	return (
		<NavigationWrapper className="home">
			<LvtText set="h1" className="home-heading">
				<Localized id="navigation-home" />
			</LvtText>

			<LvtLink className="home-link" to={paths.eventInfoPath(slug)}>
				<IconInfo size="40" className="icon icon-info" />
				<h2>
					<Localized id="home-info-heading" />
				</h2>
				<small>
					<Localized id="home-info-description" />
				</small>
			</LvtLink>

			{performers.length > 0 && (
				<LvtLink
					className={`home-link${!performers.length > 0 ? " disabled" : ""}`}
					to={paths.performersPath(slug)}
					onClick={e => checkDisabled(e, performers.length > 0)}
				>
					<IconStar size="40" className="icon icon-performers" />
					<h2>
						<Localized id="home-performers-heading" />
					</h2>
					<small>
						<Localized id="home-performers-description" />
					</small>
				</LvtLink>
			)}

			{schedules.length > 0 && (
				<LvtLink
					className={`home-link${!schedules.length > 0 ? " disabled" : ""}`}
					to={paths.schedulePath(slug)}
					onClick={e => checkDisabled(e, schedules.length > 0)}
				>
					<IconCalendar size="40" className="icon icon-schedule" />
					<h2>
						<Localized id="home-schedule-heading" />
					</h2>
					<small>
						<Localized id="home-schedule-description" />
					</small>
				</LvtLink>
			)}

			{rooms.length > 0 && (
				<LvtLink
					className={`home-link${!rooms.length > 0 ? " disabled" : ""}`}
					to={paths.allChatsPath(slug)}
					onClick={e => checkDisabled(e, rooms.length > 0)}
				>
					<IconComments size="40" className="icon icon-chat" />
					<h2>
						<Localized id="home-discussion-heading" />
					</h2>
					<small>
						<Localized id="home-discussion-description" />
					</small>
				</LvtLink>
			)}

			{polls.length > 0 && (
				<LvtLink
					className={`home-link${!polls.length > 0 ? " disabled" : ""}`}
					to={paths.pollsPath(slug)}
					onClick={e => checkDisabled(e, polls.length > 0)}
				>
					<IconBarChart size="40" className="icon icon-chat" />
					<h2>
						<Localized id="home-polls-heading" />
					</h2>
					<small>
						<Localized id="home-polls-description" />
					</small>
				</LvtLink>
			)}

			{hasMaps && (
				<LvtLink
					className={`home-link${!hasMaps ? " disabled" : ""}`}
					to={paths.venuemapsPath(slug)}
					onClick={e => checkDisabled(e, hasMaps)}
				>
					<IconMap size="40" className="icon icon-chat" />
					<h2>
						<Localized id="home-map-heading" />
					</h2>
					<small>
						<Localized id="home-map-description" />
					</small>
				</LvtLink>
			)}
		</NavigationWrapper>
	);
};

export default Home;
