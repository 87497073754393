/** @format */

import { createContext, useContext, useState } from "react";

const ChatContext = createContext({});

export const ChatContextProvider = ({ children }) => {
	const [quotedMsg, setQuotedMsg] = useState(null);
	const value = { quotedMsg, setQuotedMsg };
	
	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
export const useChatContext = () => useContext(ChatContext);
