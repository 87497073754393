import { useState, useEffect } from 'react';

export const useMonitorWidth = (ref) => {
    const [width, setWidth] = useState(0)

    useEffect(() => {
        setWidth(ref.current.offsetWidth);
        const handleResize = () => {
            setWidth(ref.current.offsetWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return width
}

export const useMonitorHeight = (ref) => {
    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(ref.current.offsetHeight);
        const handleResize = () => {
            setHeight(ref.current.offsetHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return height
}


export const useMonitorSize = (ref) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
        const handleResize = () => {
            setWidth(ref.current.offsetWidth)
            setHeight(ref.current.offsetHeight);
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [width, height]
}