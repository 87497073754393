import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { encodeQueryParams } from "utils/api";
import { useAccessKey } from "./generic";
import { useApiGet } from "./generic";


export function useApiGetEvent() {
    const apipath = '/api/v1/events/';
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [get, state, resetState] = useApiGet({ apipath });

    const access_key = useAccessKey();


    useEffect(() => {
        getEvent(slug)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    async function getEvent(event_slug) {
        const [resp, ok] = await get(`/api/v1/events/${event_slug}/${encodeQueryParams({ access_key: access_key })}`);
        if (ok) {
            dispatch({ type: 'EVENT::SET_ACTIVE_EVENT', payload: resp })
        }

    }

    return [state, getEvent, resetState];
}

export function useApiGetEventAppConfigs() {
    const apipath = '/api/v1/events/';
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [get, state, resetState] = useApiGet({ apipath });

    useEffect(() => {
        getConfig(slug)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    async function getConfig(event_slug) {
        const [resp, ok] = await get(`/api/v1/events/${event_slug}/event_app/`);
        if (ok) {
            dispatch({ type: 'CONFIG::SET_CONFIG', payload: resp })
        }

    }

    return [state, getConfig, resetState];
}