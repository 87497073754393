/** @format */

import websocketMiddleware from "middlewares/websocket";
import analyticsMiddleware from "middlewares/analytics";
import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import activeEventReducer from "./reducers/activeEvent";
import activeChatReducer from "./reducers/activeRoom";
import configReducer from "./reducers/appConfig";
import chatroomsReducer from "./reducers/chatrooms";
import connectionReducer from "./reducers/connection";
import eventReducer from "./reducers/event";
import pollsModuleReducer from "./reducers/module-polls";
import performersReducer from "./reducers/performers";
import schedulesReducer from "./reducers/schedules";
import userReducer from "./reducers/user";
import schedulePerformersReducer from "./reducers/schedulePerformers";

const loadStore = () => {
	try {
		const serializedSessionState = sessionStorage.getItem("eventApp");
		const serializedLocalState = localStorage.getItem("eventApp");

		if (serializedSessionState === null) {
			return undefined;
		}
		const sessionJSON = JSON.parse(serializedSessionState);

		// Complement the storage with stuff saved in localstorage
		if (serializedLocalState) {
			let localJSON = JSON.parse(serializedLocalState);
			for (let key in localJSON) {
				sessionJSON[key] = localJSON[key];
			}
		}

		return sessionJSON;
	} catch (err) {
		console.error(err);
		return undefined;
	}
};
const saveStore = (session, local = null) => {
	try {
		const serializedState = JSON.stringify(session);
		sessionStorage.setItem("eventApp", serializedState);

		if (local) {
			const serializedLocalState = JSON.stringify(local);
			localStorage.setItem("eventApp", serializedLocalState);
		}
	} catch (err) {
		console.error("Failed to saveStore: ", err);
	}
};

const rootReducer = combineReducers({
	user: userReducer,
	event: eventReducer,
	connection: connectionReducer,
	chat: activeChatReducer,
	chatrooms: chatroomsReducer,
	// Modules
	//messaging: messagingModuleReducer,
	polls: pollsModuleReducer,
	performers: performersReducer,
	schedules: schedulesReducer,
	activeEvent: activeEventReducer,
	config: configReducer,
	schedulePerformers: schedulePerformersReducer,
});

const middlewares = [thunk, websocketMiddleware, analyticsMiddleware];
let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
	if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
	}
	const disabledActions = ["TIMER::SET_TIME"];

	const logger = createLogger({
		timestamp: false,
		duration: false,
		predicate: (getState, action) => !disabledActions.includes(action.type),
		collapsed: (getState, action, logEntry) => !logEntry.error,
	});
	middlewares.push(logger);
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const persistedState = loadStore();

const store = createStore(rootReducer, persistedState, enhancer);

store.subscribe(() => {
	saveStore(
		{
			// Sessionstorage
			user: store.getState().user,
			connection: store.getState().connection,
		},
		{
			// Localstorage
			activeEvent: store.getState().activeEvent,
		}
	);
});

export default store;
