import Join from "components/bigScreen/join";
import { LvtRow } from "components/lvtComponents/components";
import { pollPath } from "constants/paths";
import { useMonitorHeight } from "hooks/monitorWidth";
import { useMonitorSize } from "hooks/monitorWidth";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { calculatePercent, calculateWidth } from "views/module-polls/functions";
import Chart from "./pieChart";

import './viewPollResults.scss';
const ViewPollResults = props => {
    const dispatch = useDispatch();
    const { slug, uuid } = useParams();
    const qrCodeValue = `${process.env.REACT_APP_DOMAIN}${pollPath(slug, uuid, '/current')}`;
    const polls = useSelector(state => state.polls.polls);
    const { question, options = [] } = polls.find(p => p.id === uuid) || {};

    useEffect(() => {
        dispatch({ type: 'WEBSOCKET::OUT::REQUEST_POLL_RESULTS', meta: 'SEND_SIGNAL' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <LvtRow id="view-pollresults">
        <Poll options={options} />
        <Join type="poll" qrCode={qrCodeValue}>
            <LvtRow className="heading-row">
                <h3 className="question">{question}</h3>
            </LvtRow>
        </Join>
    </LvtRow>
}

const Poll = props => {
    const { options } = props;
    const colors = [
        '#FF9AA2',
        '#FFDAC1',
        '#B5EAD7',
        '#FFB7B2',
        '#E2F0CB',
        '#C7CEEA',
        '#EE8E88'
    ]

    const containerRef = useRef();
    const answersRef = useRef();
    const [width, height] = useMonitorSize(containerRef);
    const answersHeight = useMonitorHeight(answersRef);
    const totalVotes = options.reduce((prev, curr) => {
        return prev += curr.votes;
    }, 0)
    const addedOptions = options.map((opt, index) => ({
        name: opt.label,
        value: opt.votes ? opt.votes : 0.00001,
        has_voted: opt.has_voted,
        percent: calculatePercent(opt.votes, totalVotes),
        color: colors[index],
        visualizerWidth: calculateWidth(opt.votes, totalVotes)
    }));

    const chartHeight = height - answersHeight;

    return <div className="poll" ref={containerRef}>
        <Chart options={addedOptions} width={width} height={chartHeight} />
        <div className="legends" ref={answersRef}>
            {addedOptions.map(opt => <Legend {...opt} />)}
        </div>
    </div>
}

const Legend = props => {
    const { name, color, percent, visualizerWidth } = props;
    return <div className="legend" >
        <LvtRow className="legend-inner">
            <p className="percent">{percent}</p>
            <p className="name">{name}</p>
        </LvtRow>
        <div className="legend-visualization">
            <div className="percentage" style={{ transform: `scaleX(${visualizerWidth})`, backgroundColor: color }} />
        </div>
    </div>
}

export default ViewPollResults;