import React, { useEffect, useRef } from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useSelector, useDispatch } from "react-redux";
import { LvtCol, LvtLink } from "components/lvtComponents/components";
import { LvtText } from "components/lvtComponents/components";
import { useParams } from "react-router";
import { LvtBtn } from "components/lvtComponents/components";
import { IconBarChart, IconChevronLeft, IconChevronRight } from "components/icons";
import { handleVote } from "middlewares/actions";
import { LivetoLoader } from "components/loader";
import { LvtRow } from "components/lvtComponents/components";
import { hasVoted, calculatePercent } from "./functions";
import { scrollIntoView } from "seamless-scroll-polyfill";

import "./poll.scss";
import { Localized } from "@fluent/react";
import { uniqueId } from "lodash";
import { Link } from "react-router-dom";
import { pollPath } from "constants/paths";
import { definePollType } from "hooks/polls";

const Poll = props => {
  const { id, type, slug } = useParams();
  const { polls = [], loading } = useSelector(state => state.polls);
  const poll = polls.find(p => p.id === id);
  const index = polls.indexOf(poll);
  const prevPoll = polls[index - 1];
  const nextPoll = polls[index + 1];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "WEBSOCKET::OUT::REQUEST_POLL_RESULTS",
      meta: "SEND_SIGNAL",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <NavigationWrapper className={`specific-poll`}>
        <LivetoLoader size="50" />
      </NavigationWrapper>
    );
  }

  if (!poll) {
    return (
      <NavigationWrapper className="specific-poll">
        <div className="poll-content">
          <LvtText set="h1" className="lvt-text-h3 poll-notfound">
            <Localized id="poll-notfound" />
          </LvtText>
        </div>
      </NavigationWrapper>
    );
  }

  const { options = [], id: pollId, question = "", show_results } = poll;
  const voted = hasVoted(options);

  const showPollResults = show_results && (voted || type === "past"); // Voted or poll has ended
  const pollCanBeVoted = !voted && type === "current";

  return (
    <NavigationWrapper className="specific-poll">
      <div className="poll-content">
        {showPollResults ? (
          <Results options={options} question={question} />
        ) : pollCanBeVoted ? (
          <>
            <LvtText set="h1" className="lvt-text-h4 question">
              {question}
            </LvtText>
            <Options options={options} pollId={pollId} />
          </>
        ) : (
          <div className="poll-placeholder">
            <IconBarChart className="poll-icon" size="100" />
            <Localized id="poll-results-shown-after-vote" />
          </div>
        )}
      </div>
      <div className="poll-footer">
        <div>
          {prevPoll && (
            <LvtLink to={pollPath(slug, prevPoll.id, `/${definePollType(prevPoll)}`)} className="poll-link">
              <div className="poll-nav-btn">
                <IconChevronLeft />
              </div>
            </LvtLink>
          )}
        </div>
        <div>
          {nextPoll && (
            <LvtLink to={pollPath(slug, nextPoll.id, `/${definePollType(nextPoll)}`)} className="poll-link">
              <div className="poll-nav-btn">
                <IconChevronRight />
              </div>
            </LvtLink>
          )}
        </div>
      </div>
    </NavigationWrapper>
  );
};

const Results = ({ options = [], question = "" }) => {
  let totalVotes = options.reduce((prev, curr) => {
    return (prev += curr.votes);
  }, 0);

  // backup if totalVotes is NaN or null
  if (!totalVotes) {
    totalVotes = 0;
  }

  const sortedOptions = options.sort((a, b) => {
    return a.votes > b.votes ? -1 : 1;
  });

  return (
    <div className="results">
      <LvtRow justify="between" align="center" className="results-heading-row">
        <LvtText set="h1" className="lvt-text-h4 results-heading">
          <Localized id="poll-result-heading" />
        </LvtText>

        <LvtText set="small" className="total-votes">
          <Localized id="poll-total-votes" vars={{ votes: totalVotes }} />
        </LvtText>
      </LvtRow>

      <LvtText set="small" className="results-question">
        {question}
      </LvtText>
      {sortedOptions.map(opt => (
        <OptionResult {...opt} totalVotes={totalVotes} key={uniqueId()} />
      ))}
    </div>
  );
};

const OptionResult = ({ has_voted, label, votes, totalVotes }) => {
  const percentage = calculatePercent(votes, totalVotes);

  return (
    <LvtCol set="link" className={`result${has_voted ? " voted" : ""}`}>
      <LvtRow className="row">
        <LvtText set="b" className="text-percent">
          {percentage}
        </LvtText>
        <LvtText set="small" className="text-label">
          {label}
        </LvtText>
      </LvtRow>
    </LvtCol>
  );
};

const Options = props => {
  const { options = [], pollId } = props;

  return (
    <>
      <div className={"options"}>
        <LvtText className="options-heading">
          <Localized id="poll-options-heading" />
        </LvtText>
        {options.map(opt => (
          <Option {...opt} key={opt.id} pollId={pollId} />
        ))}
      </div>
    </>
  );
};

const Option = ({ id, label = "", has_voted = false, pollId }) => {
  const dispatch = useDispatch();

  function onClick() {
    const payload = {
      answer_id: id,
      poll_id: pollId,
    };
    dispatch(handleVote(payload));
  }
  return (
    <LvtCol set="link" className={`option${has_voted ? " voted" : ""}`} onClick={onClick}>
      <LvtRow className="opt-row">
        <div className="dot" />
        <LvtText set="small" className="option-text">
          {label}
        </LvtText>
      </LvtRow>
    </LvtCol>
  );
};

export default Poll;
