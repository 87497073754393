/** @format */

import { Localized } from "@fluent/react";
import { Overlay } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { IconCopy, IconDotsHorizontal, IconReply } from "components/icons";
import LongPressWrapper from "components/wrappers/longPressWrapper";
import { wsOut } from "constants/actionTypes";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { Emojione } from "react-emoji-render";
import { useDispatch } from "react-redux";
import { parseInitials } from "utils/strings";
import { useChatContext } from "./chatContext";

function textContainsLink(message, regex) {
	if (regex.test(message)) {
		return true;
	}
	return false;
}

const Message = props => {
	const { messages = [], bigScreen, chat_history = [] } = props;
	const messageInfo = messages[0];
	return (
		<div className={`user-message${bigScreen ? " big" : ""}`}>
			<MessageIcon {...messageInfo} />
			<div className="message-body">
				<Sender {...messageInfo} />
				<div className="messages">
					{messages.map(m => (
						<MessageContent {...m} key={uniqueId()} chat_history={chat_history} />
					))}
				</div>
			</div>
		</div>
	);
};

const MessageActions = ({ message, sender, sender_name, close, room_id, message_id }) => {
	const { setQuotedMsg } = useChatContext();
	const ref = useClickOutside(() => close());
	const dispatch = useDispatch();
	const isAnonymous = sender === "ANONYMOUS_PERSON";
	function react(reaction) {
		dispatch({
			type: wsOut.REACT_TO_MESSAGE,
			payload: {
				reaction,
				message_id,
				room_id,
			},
			meta: "SEND_SIGNAL",
		});
	}
	const reply = () => setQuotedMsg({ message, message_id, sender_name, sender, isAnonymous });
	const handleAction = (type, data) => {
		try {
			switch (type) {
				case "reaction":
					react(data);
					break;
				case "reply":
					reply();
					break;
				case "copy":
					navigator.clipboard.writeText(message);
					break;

				default:
					break;
			}
		} catch (error) {
			console.error(`Error handling an action: ${type}`, error);
		} finally {
			close();
		}
	};
	return (
		<Overlay
			fixed={true}
			color="transparent"
			backgroundOpacity={0.35}
			blur={10}
			className="message-actions-overlay"
		>
			<div className="message-actions" ref={ref}>
				<ReactionsMenu room_id={room_id} message_id={message_id} react={handleAction} />
				<div className="msg">
					<div className="msg-content">
						<p>{message}</p>
					</div>
				</div>
				<div className="action-menu">
					<button className="action-menu-button" onClick={() => handleAction("reply")}>
						<p>
							<Localized id="chat-action-menu-reply">Reply</Localized>
						</p>
						<IconReply />
					</button>
					<button className="action-menu-button" onClick={() => handleAction("copy")}>
						<p>
							<Localized id="chat-action-menu-copy">Copy</Localized>
						</p>
						<IconCopy />
					</button>
				</div>
			</div>
		</Overlay>
	);
};

const Quoted = props => {
	const { quoted, isQuotedRemoved } = props;
	return (
		<div className="quoted-msg-container">
			<div className="sender">
				{quoted.isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : quoted.sender_name}
			</div>
			<div className="message">
				{isQuotedRemoved ? (
					<span className="quoted-removed">
						<Localized id="chat-message-removed">This message has been removed</Localized>
					</span>
				) : (
					<MessageWrapper message={quoted.message} />
				)}
			</div>
		</div>
	);
};

const MessageContent = props => {
	const { message, sender, sender_name, held, reactions = {}, room_id, message_id, quoted, chat_history } = props;
	const hasReactions = Object.keys(reactions).length > 0;
	const [visible, setVisible] = useState(false);
	const [actionsOpened, setAtionsOpened] = useState(false);
	const ref = useClickOutside(() => setAtionsOpened(false));
	const isQuotedRemoved = quoted && !chat_history.find(m => m.message_id === quoted?.message_id);

	const actionProps = { message, sender, room_id, message_id, sender_name, close: () => setVisible(false) };

	function openActionMenu() {
		if (sender !== "MODERATOR_MESSAGE") {
			setAtionsOpened(!actionsOpened);
		}
	}
	return (
		<div className="message">
			{visible && <MessageActions {...actionProps} />}
			<div
				className={`message-container${moderator(sender) ? " moderator" : ""} ${quoted ? "quoted" : ""}`}
				onClick={openActionMenu}
			>
				{actionsOpened && (
					<button ref={ref} className="message-menu-btn" onClick={() => setVisible(true)}>
						<IconDotsHorizontal />
					</button>
				)}
				{moderator(sender) && (
					<p className="moderator-title">
						<Localized id="moderator" />
					</p>
				)}
				{quoted && <Quoted quoted={quoted} isQuotedRemoved={isQuotedRemoved} />}
				<div className={`message-content ${held ? "held" : ""}`}>
					<MessageWrapper message={message} />
					{held && (
						<Localized id="chat-msg-held-info">
							<div className="msg-held-info">This message has been held for review</div>
						</Localized>
					)}
				</div>

				{hasReactions && (
					<div className="message-reactions">
						{Object.keys(reactions).map((emoji, idx) => (
							<Reaction
								reaction={emoji}
								amount={reactions[emoji]}
								room_id={room_id}
								message_id={message_id}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

const ReactionsMenu = ({ message_id, room_id, react }) => {
	const possibleReactions = ["smile", "grin", "rofl", "wave", "thumbs_up", "clap", "heart", "sick"];
	return (
		<div className="reaction-menu">
			{possibleReactions.map(reaction => (
				<div
					className="reaction"
					onClick={() => react("reaction", reaction)}
					key={`message_reaction_${reaction}`}
				>
					<Emojione text={`:${reaction}:`} />
				</div>
			))}
		</div>
	);
};

const Reaction = ({ reaction, amount, room_id, message_id }) => {
	const dispatch = useDispatch();
	// ROOM ID, MESSAGE ID, REACTION
	function react() {
		dispatch({
			type: wsOut.REACT_TO_MESSAGE,
			payload: {
				reaction,
				message_id,
				room_id,
			},
			meta: "SEND_SIGNAL",
		});
	}
	const emoji = `:${reaction}:`;
	if (amount === 0) {
		return null;
	}
	return (
		<div className={"message-reaction"} onClick={react}>
			<Emojione text={emoji} />
			<span className="amount">{amount}</span>
		</div>
	);
};

const MessageWrapper = props => {
	const { message } = props;

	const linkRegex = new RegExp(/(https?:\/\/\S+)/i);

	if (textContainsLink(message, linkRegex)) {
		let messageArray = [];
		const splitted_message = message.split(linkRegex);
		for (let [index, sentence] of splitted_message.entries()) {
			if (textContainsLink(sentence, linkRegex)) {
				messageArray.push(
					<a key={index} target="_blank" rel="noreferrer" className={"message-link"} href={sentence}>
						{sentence}
					</a>
				);
			} else {
				if (sentence.length > 0) {
					messageArray.push(<Emojione key={index} onlyEmojiClassName="liveto-chat-emoji" text={sentence} />);
				}
			}
		}
		return messageArray;
	}

	return <Emojione onlyEmojiClassName="liveto-chat-emoji" text={message} />;
};

function anonymous(sender) {
	if (sender === "ANONYMOUS_PERSON") {
		return true;
	}
	return false;
}

function moderator(sender) {
	if (sender === "MODERATOR_MESSAGE") {
		return true;
	}
	return false;
}

const MessageIcon = props => {
	const { icon = null, sender, sender_name = "" } = props;

	if (moderator(sender)) {
		return null;
	}

	return (
		<div className="message-icon" style={icon && !anonymous(sender) ? { backgroundImage: `url("${icon}")` } : {}}>
			{(!icon || anonymous(sender)) && <>{anonymous(sender) ? "?" : parseInitials(sender_name)}</>}
		</div>
	);
};

const Sender = props => {
	const { sender_name = "", sender } = props;
	let name = sender_name;
	if (anonymous(sender)) {
		name = "Anonymous user";
	}
	if (moderator(sender)) {
		return null;
	}
	return <div className="sender">{name}</div>;
};

export default Message;
