const initialState = [];

const performersReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PERFORMERS::SET_PERFORMERS":
            return [...action.payload];

        default:
            return state;
    }
};

export default performersReducer;
