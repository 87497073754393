import { useDispatch } from "react-redux";
import { Localized } from "@fluent/react";
import './appNotFound.scss';
import { LvtBtn, LvtText } from "components/lvtComponents/components";
import { useHistory } from "react-router";

const AppNotFound = ({ target = "event" }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const exit = () => {
        dispatch({ type: "EVENT::EXIT" });
        history.push('/');
    }
    return <div className="not-found">
        <LvtText set="h1">404</LvtText>
        <Localized id={`${target}-not-found`} />
        <LvtText set="small">
            <Localized id={`${target}-not-found-description`} />
        </LvtText>
        <LvtBtn set="primary" onClick={exit}>
            <Localized id="back-to-start"/>
        </LvtBtn>
    </div>;
}

export default AppNotFound;