import React from 'react';
import { LivetoLoader } from "./loader";
import 'scss/components/loadingScreen.scss';
import { Localized } from '@fluent/react';

const LoadingScreen = props => {
    return <div className="loading-screen">
        <LivetoLoader size="50" />
        <p><Localized id="loading-content" /></p>
    </div>
}

export default LoadingScreen;