import Helmet from "react-helmet";
import { useSelector } from "react-redux";


const HeadInjector = () => {
    const { custom_css } = useSelector(state => state.config);
    const { name } = useSelector(state => state.event);

    return <Helmet>
        <title>{name}</title>
        <style>
            {custom_css}
        </style>
    </Helmet>
}

export default HeadInjector;