/** @format */

export function hasVoted(options) {
	return options.some((opt) => opt.has_voted);
}

export function calculateWidth(curr = 0, total = 0) {
	if (curr === 0 || total === 0) {
		return 0;
	}
	const amount = parseInt(curr) / parseInt(total);
	return amount;
}

export function calculatePercent(curr = 0, total = 0) {
	if (curr === 0 || total === 0) {
		return `0%`;
	}
	const amount = (parseInt(curr) / parseInt(total)) * 100;

	return `${parseFloat(amount).toFixed(1)}%`;
}
