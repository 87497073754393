import { Localized } from "@fluent/react";
import { LivetoLoader } from "components/loader";
import './appLoading.scss';

const AppLoading = () => {
    return <div className="loading">
        <LivetoLoader size="100" />
        <Localized id="event-loading" />
    </div>
}

export default AppLoading;