/** @format */

import { LvtText, LvtRow, LvtCol, LvtLink } from "components/lvtComponents/components";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { performersSinglePath } from "constants/paths";
import { uniqueId } from "lodash";
import moment from "moment";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getFilteredSlots, parseUniqueDates } from "utils/time";
import { IconChevronLeft } from "components/icons";
import { useEffect } from "react";
import { useState } from "react";
import { LvtBtn } from "components/lvtComponents/components";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { Localized, useLocalization } from "@fluent/react";
import "./schedules.scss";
import { Spoiler } from "@mantine/core";
import { useCombinePerformers, useGenerateMultilingualData } from "hooks/generic";

function findNearestDate(dates) {
	// There should not be a situation where user gets to the view using this function and there is no dates
	// => We can assume the dates value is always populated

	if (dates.length === 1) return dates[0];

	const thisDay = moment().format("YYYY-MM-DD");

	let nearestDate;
	let smallestDiff = 1000000000;

	dates.forEach(date => {
		let diff = moment(date).diff(moment(thisDay), "days"); // Something between -100000 - +10000000

		// Can ignore the negative values, since that is the difference calculated backwards
		// -> Find the closest value (Closest date since the difference with today and the desired date is closest)

		// Current iteration is the current date
		if (diff === 0) {
			smallestDiff = diff;
			nearestDate = date;
		}

		if (diff > 0) {
			// First loop set the initial values
			if (!nearestDate) {
				nearestDate = date;
				smallestDiff = diff;
			} else {
				// Set closer values
				if (diff < smallestDiff) {
					nearestDate = date;
					smallestDiff = diff;
				}
			}
		}
	});

	if (nearestDate) {
		return nearestDate;
	}

	return dates[dates.length - 1];
}

const Schedule = props => {
	const history = useHistory();
	const { id } = useParams();
	const schedule = useSelector(state => state.schedules).find(s => s.id === parseInt(id));
	const { timeslots = [], name = "" } = schedule || {};
	const dates = parseUniqueDates(timeslots);

	const [filter, setFilter] = useState(findNearestDate(dates));

	useEffect(() => {
		if (dates.length > 0) {
			setFilter(findNearestDate(dates));
		}
	}, [dates.length]);

	const filteredSlots = getFilteredSlots(timeslots, filter);

	return (
		<NavigationWrapper className="schedules">
			<LvtRow>
				<LvtBtn set="link" className="back" onClick={() => history.goBack()}>
					<IconChevronLeft className="back-icon" />
					<Localized id="back" />
				</LvtBtn>
			</LvtRow>
			<LvtRow>
				<LvtText set="h1" className="lvt-text-h3 schedule-name">
					{name}
				</LvtText>
			</LvtRow>
			<ScheduleDatePicker filter={filter} setFilter={setFilter} dates={dates} />
			{dates.length > 0 && filteredSlots.map(slot => <Timeslot {...slot} key={uniqueId()} />)}
		</NavigationWrapper>
	);
};

const ScheduleDatePicker = props => {
	const { dates = [], filter, setFilter } = props;
	const activeRef = useRef();
	useEffect(() => {
		if (activeRef.current) {
			scrollIntoView(activeRef.current, { block: "nearest", behavior: "auto" });
		}
	}, [filter]);
	return (
		<div className={`navigation${dates.length > 1 ? " multiple" : ""}`}>
			<div className="navigation-dates">
				{dates.map(d => (
					<button
						key={uniqueId()}
						className={`navigation-date lvt-btn-${filter === d ? "primary active" : "link"}`}
						ref={filter === d ? activeRef : null}
						onClick={() => setFilter(d)}
					>
						{moment(d).format("DD.MM.YYYY")}
					</button>
				))}
			</div>
		</div>
	);
};

const Timeslot = props => {
	const { start_time, end_time, name, display_name, description, translated_description, performers } = props;
	const start = moment(start_time).format("HH:mm");
	const end = moment(end_time).format("HH:mm");
	const { language = "en" } = useSelector(state => state.user);
	const isMultilingualDesc = translated_description[language];
	const { l10n } = useLocalization();
	const { generate, generateHtml } = useGenerateMultilingualData();
	return (
		<LvtRow className="timeslot" align="start">
			<div className="time">
				<LvtCol align="center">
					<span className="timeslot-text">{start}</span>
					<span className="timeslot-text middle">-</span>
					<span className="timeslot-text end">{end}</span>
				</LvtCol>
			</div>
			<LvtCol className="info">
				<LvtText set="h2" className="timeslot-heading timeslot-text">
					{generate(display_name, name)}
				</LvtText>
				<Spoiler
					maxHeight={120}
					showLabel={l10n.getString("schedules-slot-description-show-button", null, "Show more")}
					hideLabel={l10n.getString("schedules-slot-description-hide-button", null, "Hide")}
				>
					<div
						set="small"
						className={`timeslot-text${isMultilingualDesc ? "-multilingual" : " lvt-text-small"}`}
					>
						{generateHtml(translated_description, description)}
					</div>
				</Spoiler>
				<LvtCol className="timeslot-extra">
					<PerformerListing performers={performers} />
				</LvtCol>
			</LvtCol>
		</LvtRow>
	);
};

const PerformerListing = props => {
	const { performers } = props;
	const [expanded, setExpanded] = useState(false);
	if (performers.length === 0) {
		return null;
	}
	const performersList = performers.map(p => <Performer {...p} />);

	return (
		<>
			<LvtBtn
				variant="sm"
				set="secondary"
				className="show-all-performers-button"
				onClick={() => setExpanded(!expanded)}
			>
				<Localized id="schedules-rest-performers" />
			</LvtBtn>
			{expanded ? performersList : null}
		</>
	);
};

const Performer = props => {
	const { slug } = useParams();
	const { schedulePerformers = [] } = useSelector(state => state);
	const findPerformer = schedulePerformers.find(p => p.id === props.id);
	const { id, image = null, name = {}, title = {} } = findPerformer;
	const { generate, generateHtml } = useGenerateMultilingualData();
	const nameToShow = generate(name);
	const titleToShow = generate(title);

	const performers = useCombinePerformers();

	const performerDataExists = performers.find(p => p.id === id);

	if (!performerDataExists) {
		return (
			<LvtRow justify="start" align="center" className="performer">
				<div className="performer-image" style={{ backgroundImage: `url("${image}")` }} />
				<LvtCol className="performer-info">
					<LvtText set="p" className="performer-name">
						{nameToShow}
					</LvtText>
					<LvtText set="small" className="performer-title">
						{titleToShow}
					</LvtText>
				</LvtCol>
			</LvtRow>
		);
	}

	return (
		<LvtLink to={performersSinglePath(slug, id)}>
			<LvtRow justify="start" align="center" className="performer">
				<div className="performer-image" style={{ backgroundImage: `url("${image}")` }} />
				<LvtCol className="performer-info">
					<LvtText set="p" className="performer-name">
						{nameToShow}
					</LvtText>
					<LvtText set="small" className="performer-title">
						{titleToShow}
					</LvtText>
				</LvtCol>
			</LvtRow>
		</LvtLink>
	);
};

export default Schedule;
