/** @format */

import { useApiGetEvent, useApiGetEventAppConfigs } from "hooks/api/event";
import { useApiGetUser } from "hooks/api/user";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Authentication from "views/authentication/authentication";
import WebsocketLoader from "./websockerLoader";
import { useEffect } from "react";
import AppLoading from "components/appLoading/appLoading";
import AppNotFound from "components/appNotFound/appNotFound";
import { useApiGetSchedule } from "hooks/api/schedule";
import HeadInjector from "./headInjector";
import { useApiGetPerformerLists } from "hooks/api/performers";

const handleLanguage = () => (dispatch, getState) => {
	const { user, event } = getState();
	const { languages = [] } = event; // Accepted languages on the event.
	const { language = "en" } = user; // User language

	let lang = languages[0] || "en";

	if (languages.includes(language)) {
		lang = language;
	}

	dispatch({
		type: "USER::CHANGE_LANGUAGE",
		payload: lang,
	});
};

const UserValidator = props => {
	const { children = null } = props;
	const dispatch = useDispatch();

	const [user] = useApiGetUser();
	const [event] = useApiGetEvent(); // event has async status of the fetch
	const [app] = useApiGetEventAppConfigs();
	const { scheduleStatus } = useApiGetSchedule();
	const [performersStatus] = useApiGetPerformerLists();

	useEffect(() => {
		const loading = event.loading || user.loading;
		const ok = event.ok && user.ok;
		if (!loading && ok) {
			dispatch(handleLanguage());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event.loading, event.ok, user.loading, user.ok]);

	// Any part of the necessary calls loading
	if (event.loading || app.loading || user.loading || scheduleStatus.loading || performersStatus.loading) {
		return <AppLoading />;
	}

	// Event doesnt exist || it has ended || it has not started
	if (!event.ok) return <AppNotFound target="event" />;

	// Event does not have an event app configuration
	if (!app.ok) return <AppNotFound target="config" />;

	// User has not authenticated
	if (!user.ok) return <Authentication />;

	return (
		<WebsocketLoader>
			<HeadInjector />
			{children}
		</WebsocketLoader>
	);
};

export default UserValidator;
