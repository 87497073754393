/** @format */

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { encodeQueryParams } from "utils/api";
import { useAccessKey } from "./generic";
import { useApiGet } from "./generic";

export function useApiGetSchedule() {
	const dispatch = useDispatch();
	const apipath = "/api/v1/events/";
	const { slug } = useParams();
	const [get, state, resetState] = useApiGet({ apipath });

	const access_key = useAccessKey();

	useEffect(() => {
		getSchedule(slug);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getSchedule(event_slug) {
		const [resp, ok] = await get(
			`/api/v1/events/${event_slug}/schedule/${encodeQueryParams({
				access_key: access_key,
				show_in: "event_app",
			})}`
		);
		if (ok) {
			dispatch({ type: "SCHEDULES::SET_SCHEDULES", payload: resp.schedules });
			dispatch({ type: "SCHEDULE_PERFORMERS::SET_SCHEDULE_PERFORMERS", payload: resp.performers });
		}
	}

	return { scheduleStatus: state, getSchedule, resetScheduleState: resetState };
}
