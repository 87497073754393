/** @format */

import React from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useSelector } from "react-redux";

import { LvtText, LvtLink, LvtRow } from "components/lvtComponents/components";
import { useParams } from "react-router-dom";
import { performerListPath } from "constants/paths";
import { Localized } from "@fluent/react";
import SinglePerformerList from "./performerList";

import "./performers.scss";
import { useGenerateMultilingualData } from "hooks/generic";

const PerformerLists = props => {
	// Artist filter is defined when user clicks an category on the performer list if categories exist.
	const { performers: performerLists = [], schedules = [] } = useSelector(state => state);

	if (performerLists.length === 1) return <SinglePerformerList singleListID={performerLists[0].id} />;

	return (
		<NavigationWrapper className="performers">
			<LvtRow justify="start">
				<LvtText set="h1" className="lvt-text-h3 performerlist-heading">
					<Localized id="navigation-performers" />
				</LvtText>
			</LvtRow>
			{performerLists.map(pl => (
				<PerformerList {...pl} key={`performerlist-${pl.id}`} />
			))}
		</NavigationWrapper>
	);
};

const PerformerList = ({ title = {}, id }) => {
	const { slug } = useParams();
	const { generate } = useGenerateMultilingualData();
	const titleToShow = generate(title);
	
	return (
		<LvtLink to={performerListPath(slug, id)} className="category">
			<LvtText set="h2" className="lvt-text-h3 category-title">
				{titleToShow.toUpperCase()}
			</LvtText>
		</LvtLink>
	);
};

export default PerformerLists;
