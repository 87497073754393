/** @format */

import React from "react";
import App from "./app";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "scss/reset.scss";
import { createRoot } from "react-dom/client";
serviceWorkerRegistration.register();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
