/** @format */

import moment from "moment";

export const compareDates = (date1, date2) => {
	let time1 = moment(date1, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
	let localizedTime1 = moment.utc(time1).local().format("YYYY-MM-DD");

	let time2 = moment(date2, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
	let localizedTime2 = moment.utc(time2).local().format("YYYY-MM-DD");

	return moment(localizedTime1).isSame(localizedTime2, "day");
};

export const getTimeStamp = (addSeconds) => {
	let time = moment().utc().local().add(addSeconds, "seconds");
	return time;
};

export const isBetweenTimes = (start, end) => {
	if (moment().isAfter(start) && moment().isBefore(end)) {
		return true;
	}
	return false;
};

export const isPast = (time) => {
	if (moment().isAfter(time)) {
		return true;
	}
	return false;
};

export const isUpcoming = (time) => {
	if (moment().isBefore(time)) {
		return true;
	}
	return false;
};

export const parseUniqueDates = (timeslots = []) => {
	// Add time shifting (Temporary solution to change slots for date to calculate from 05:00 -> 05:00)
	// We subtract 5 hours from the current time, to shift the filtering from 00:00 to 05:00
	const dates = [];
	for (let slot of timeslots) {
		dates.push(moment(slot.start_time).subtract(5, 'hours').format("YYYY-MM-DD"));
	}
	return [...new Set(dates)];
};

export function getFilteredSlots(slots, date) {
	// Add time shifting (Temporary solution to change slots for date to calculate from 05:00 -> 05:00)
	// We subtract 5 hours from the current time, to shift the filtering from 00:00 to 05:00
	return slots.filter((s) => moment(s.start_time).subtract(5, 'hour').format("YYYY-MM-DD") === date);
}
