/** @format */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { encodeQueryParams } from "utils/api";
import { useAccessKey } from "./generic";
import { useApiGet } from "./generic";

export function useApiGetPerformerLists() {
	const dispatch = useDispatch();
	const apipath = "/api/v1/events/";
	const { slug } = useParams();
	const [get, state, resetState] = useApiGet({ apipath });

	const access_key = useAccessKey();

	useEffect(() => {
		getPerformers(slug);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getPerformers(event_slug) {
		const [resp, ok] = await get(
			`/api/v1/events/${event_slug}/performers/${encodeQueryParams({
				access_key: access_key,
				show_in: "event_app",
			})}`
		);
		if (ok) {
			dispatch({ type: "PERFORMERS::SET_PERFORMERS", payload: resp });
		}
	}

	return [state, getPerformers, resetState];
}
