/** @format */

import Join from "components/bigScreen/join";

import { LvtRow } from "components/lvtComponents/components";
import { chatPath } from "constants/paths";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import ChatFeed from "views/module-chat/chatFeed";

import "./viewChatFeed.scss";

const ViewChatFeed = props => {
	const { slug, room } = useParams();
	const dispatch = useDispatch();
	const { joined_rooms, online } = useSelector(state => state.connection);
	const chatrooms = useSelector(state => state.chatrooms);
	const { event_main_image, name } = useSelector(state => state.event);
	const roomdata = useSelector(state => state.chat);
	const { room_name = "", topic = "" } = roomdata;

	const qrCodeValue = `${process.env.REACT_APP_DOMAIN}${chatPath(slug, room)}`;

	useEffect(() => {
		if (joined_rooms.includes(room) && online) {
			const pl = chatrooms.find(c => c.room_id === room);
			if (pl) {
				dispatch({ type: "ACTIVE_CHAT::SET_ACTIVE_ROOM", payload: pl });

				dispatch({
					type: "WEBSOCKET::OUT::REQUEST_CHAT_HISTORY",
					payload: { room_id: pl.room_id, skip: 0 },
					meta: "SEND_SIGNAL",
				});
			}
		} else {
			dispatch({ type: "WEBSOCKET::OUT::REQUEST_JOIN_ROOM", payload: { room_id: room }, meta: "SEND_SIGNAL" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [online, chatrooms.length]);

	return (
		<LvtRow className="view-chatfeed">
			<div className="chatfeed-container">
				<div className="chat">
					<ChatFeed bigScreen />
				</div>
			</div>

			<Join type="chat" qrCode={qrCodeValue}>

				<LvtRow className="heading-row">
					<div className="event-image" style={{ backgroundImage: `url("${event_main_image}")` }} />
					<div className="heading">
						<h1 className="event-name">{name}</h1>
						<h2 className="lvt-text-h1 room-name">{room_name}</h2>
						<h2 className="room-topic">{topic}</h2>
					</div>
				</LvtRow>

			</Join>

		</LvtRow>
	);
};

export default ViewChatFeed;
