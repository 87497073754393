/** @format */

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { defineHeaders } from "utils/api";
import { getCookie } from "utils/cookies";
import { useApiGet } from "./generic";

export function useApiGetUser() {
	const apipath = "/api/v1/users/me/";
	const dispatch = useDispatch();
	const [get, state, resetState] = useApiGet({ apipath, headers: defineHeaders(true) });
	const { slug } = useParams();

	useEffect(() => {
		if (getCookie(process.env.REACT_APP_AUTH_COOKIE)) {
			getUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getUser() {
		const [resp, ok] = await get(apipath);
		if (ok) {
			if (sessionStorage.getItem("visited") !== resp.chat_user_id) {
				await saveVisit(slug, resp.chat_user_id);
			}
			dispatch({ type: "USER::SET_USER", payload: resp });
		}
	}

	return [state, getUser, resetState];
}

const saveVisit = async (slug, chat_user_id) => {
	const visitEndpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v1/events/${slug}/save_visit/`;
	// Registering attendee's visit for the attendance management
	const visitBody = { platform: "event_app" };
	const response = await fetch(visitEndpoint, {
		headers: { "Content-Type": "Application/json", Authorization: `Token ${getCookie(process.env.REACT_APP_AUTH_COOKIE)}` },
		body: JSON.stringify(visitBody),
		method: "POST",
	});
	if (response.ok) {
		sessionStorage.setItem("visited", chat_user_id);
	} else console.error("Error saving attendee visit");
};
