/** @format */

import Scanner from "components/scanner";
import React, { useState } from "react";
import { Redirect } from "react-router";
import Header from "components/header/header";
import "./scan.scss";
import { LvtText } from "components/lvtComponents/components";
import { LivetoLoader } from "components/loader";
import { useSelector } from "react-redux";
import { eventPath } from "constants/paths";
import { Localized } from "@fluent/react";

const Scan = props => {
	const [loading, setLoading] = useState(false);
	const activeEvent = useSelector(state => state.activeEvent);

	function handleScan(result) {
		if (!loading) {
			const { text } = result;
			if (text.includes(process.env.REACT_APP_DOMAIN)) {
				setLoading(true);
				window.location.href = text;

			}
		}

	}

	if (activeEvent) {
		return <Redirect to={eventPath(activeEvent)} />;
	}

	return (
		<div className="scanner">
			{loading && (
				<div className="loading-overlay">
					<LivetoLoader size="50" />
				</div>
			)}
			<Header />
			<div>
				<LvtText set="h3" className="heading">
					<Localized id="scan-heading" />
				</LvtText>
				<LvtText set="p" className="instructions">
					<Localized id="scan-instructions" />
				</LvtText>
			</div>
			<div className="scan">
				<Scanner
					delay={500}
					width={"100%"}
					height={"100%"}
					onUpdate={(err, result) => {
						if (result) {
							handleScan(result);
						}
					}}
				/>
			</div>
		</div>
	);
};

export default Scan;
