import React from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import Webcam from "react-webcam";

const MATRIX_FORMATS = {
    '1D': [1, 2, 3, 4, 6, 7, 8, 14, 15],
    ETC: [0, 5, 9, 10, 11, 12, 13, 16]
};


const Scanner = ({
    onUpdate,
    onError,
    width = "100%",
    height = "100%",
    facingMode = "environment",
    torch,
    delay = 500,
    videoConstraints,
    stopStream,
}) => {
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
        const codeReader = new BrowserMultiFormatReader();
        const imageSrc = webcamRef?.current?.getScreenshot();
        if (imageSrc) {
            codeReader
                .decodeFromImage(undefined, imageSrc)
                .then((result) => {
                    onUpdate(null, { ...result, is1DMatrix: MATRIX_FORMATS["1D"].includes(result.getBarcodeFormat()) });
                })
                .catch((err) => {
                    onUpdate(err);
                });
        }
    }, [onUpdate]);

    React.useEffect(() => {
        // Turn on the flashlight if prop is defined and device has the capability
        if (
            typeof torch === "boolean" &&
            navigator?.mediaDevices?.getSupportedConstraints().torch
        ) {
            const stream = webcamRef?.current?.video.srcObject;
            const track = stream?.getVideoTracks()[0]; // get the active track of the stream
            if (
                track &&
                track.getCapabilities().torch &&
                !track.getConstraints().torch
            ) {
                track
                    .applyConstraints({
                        advanced: [{ torch }],
                    })
                    .catch((err) => onUpdate(err));
            }
        }
    }, [torch, onUpdate]);

    React.useEffect(() => {
        if (stopStream) {
            let stream = webcamRef?.current?.video.srcObject;
            if (stream) {
                stream.getTracks().forEach((track) => {
                    stream.removeTrack(track);
                    track.stop();
                });
                stream = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopStream]);

    React.useEffect(() => {
        const interval = setInterval(capture, delay);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Webcam
            width={width}
            height={height}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={
                videoConstraints || {
                    facingMode,
                }
            }
            audio={false}
            onUserMediaError={onError}
        />
    );
};

export default Scanner;