/** @format */

const initialState = [];

const chatroomsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "WEBSOCKET::INIT": {
			return action.payload.rooms.filter((r) => r.live_room);
		}

		default:
			return state;
	}
};

export default chatroomsReducer;
