const { useSelector } = require("react-redux")


export const useLocalizedContent = (content = {}) => {
    const { language } = useSelector(state => state.user);
    const { languages = [] } = useSelector(state => state.event);

    if (content && Object.keys(content).length > 0) {
        if (content[language]) {
            return content[language]
        }
        if (content[languages[0]]) {
            return content[languages[0]]
        }

        return Object.values(content)[0];
    }
    return "";
}