/** @format */

const initialState = [];

const schedulePerformersReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SCHEDULE_PERFORMERS::SET_SCHEDULE_PERFORMERS":
			return action.payload;

		default:
			return state;
	}
};

export default schedulePerformersReducer;
