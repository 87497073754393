/** @format */

// Attendee paths
export const eventPath = slug => `/${slug}`;
export const eventInfoPath = slug => `${eventPath(slug)}/info`;
export const allChatsPath = slug => `${eventPath(slug)}/chat`;
export const chatPath = (slug, room) => `${allChatsPath(slug)}/${room}`;

export const pollsPath = slug => `${eventPath(slug)}/polls`;
export const pollPath = (slug, id, type = "") => `${eventPath(slug)}/polls/${id}${type}`;
export const schedulePath = slug => `${eventPath(slug)}/schedule`;
export const scheduleSinglePath = (slug, id) => `${eventPath(slug)}/schedule/${id}`;

export const performersPath = slug => `${eventPath(slug)}/performerlists`;
export const performerListPath = (slug, id) => `${performersPath(slug)}/${id}`;
export const performersSinglePath = (slug, performer_id) => `${eventPath(slug)}/performer/${performer_id}`;

export const infoPath = slug => `${eventPath(slug)}/info`;
export const attendeesPath = slug => `${eventPath(slug)}/attendees`;
export const passcodePath = slug => `${eventPath(slug)}/passcode`;
export const venuemapsPath = slug => `${eventPath(slug)}/venuemaps`;
export const currentMapPath = (slug, map) => `${venuemapsPath(slug)}/${map}`;
// Event organizer paths for viewing chat / polls etc..

export const organizerPath = slug => `${eventPath(slug)}/organizer`;
export const pollResultsPath = (slug, uuid) => `${organizerPath(slug)}/pollresults/${uuid}`;

export const chatFeedPath = (slug, room) => `${organizerPath(slug)}/chatfeed/${room}`;

export const userPath = slug => `${eventPath(slug)}/user`;
