/** @format */

import { Localized } from "@fluent/react";
import { IconSend, IconTimes } from "components/icons";
import { useSilenceTimer } from "hooks/silence";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useChatContext } from "./chatContext";
import { makeSubstring } from "utils/strings";

const SendMessage = props => {
	const dispatch = useDispatch();
	const { first_name, last_name, image = null } = useSelector(state => state.user);

	const { online, chat_user_id, is_moderator = false } = useSelector(state => state.connection);
	const { room_id, restricted = false } = useSelector(state => state.chat);
	const [message, setMessage] = useState("");
	const { quotedMsg, setQuotedMsg } = useChatContext();

	const [isSilenced, timeUntilUnmuted] = useSilenceTimer();
	function validateMessage(e) {
		// Add checks etc
		setMessage(e.target.value);
	}

	function sendMessage() {
		const name = last_name !== "(guest)" ? `${first_name} ${last_name}` : first_name;
		const payload = {
			sender: "PERSON",
			sender_name: name,
			message: message,
			room_id: room_id,
			icon: image,
			user_id: chat_user_id,
			quoted: quotedMsg,
		};
		dispatch({
			type: "WEBSOCKET::OUT::SEND_MESSAGE",
			payload: payload,
			meta: "SEND_SIGNAL",
		});
		setMessage("");
		setQuotedMsg(null);
	}

	function enter(e) {
		if ((e.key === "Enter" || e.keyCode === 13) && message.trim().length > 0) {
			sendMessage();
		}
	}

	function buttonDisabled() {
		if (message.trim().length === 0 && online) {
			return true;
		}
		return false;
	}

	return (
		<div className="send-container">
			{quotedMsg && <ReplyTo quotedMsg={quotedMsg} />}

			<div className="send">
				{restricted && !is_moderator && (
					<div className="restricted-messaging">
						<Localized id="chat-send-restricted" />
					</div>
				)}
				<Localizer
					isSilenced={isSilenced}
					minutes={timeUntilUnmuted.minutes}
					seconds={timeUntilUnmuted.seconds}
				>
					<textarea
						className="input"
						maxLength="500"
						placeholder={
							isSilenced
								? `Timeout for ${timeUntilUnmuted.minutes}minutes ${timeUntilUnmuted.seconds}seconds`
								: "Type a message"
						}
						disabled={isSilenced || restricted}
						onChange={e => validateMessage(e)}
						value={message}
						onKeyPress={e => enter(e)}
					/>
				</Localizer>
				<button className="button" disabled={buttonDisabled()} onClick={() => sendMessage()}>
					<IconSend size="20" />
					<div className="char-counter">{message.length}/500</div>
				</button>
			</div>
		</div>
	);
};

const ReplyTo = props => {
	const { quotedMsg = {} } = props;
	const { message, message_id, sender_name, sender, isAnonymous } = quotedMsg;
	const { setQuotedMsg } = useChatContext();
	
	const subsMsg = makeSubstring(message, 0, 99, 100);

	return (
		<div className="reply-message-container">
			<div className="message-container">
				<div className="sender">
					{isAnonymous ? <Localized id="chat-anonymous-name">Anonymous</Localized> : sender_name}
				</div>
				<div className="message">{subsMsg}</div>
			</div>
			<div className="unquote-button" onClick={() => setQuotedMsg(null)}>
				<IconTimes size="20" />
			</div>
		</div>
	);
};

const Localizer = props => {
	if (props.isSilenced) {
		return (
			<Localized
				id={"chat-send-timeout"}
				attrs={{ placeholder: true }}
				vars={{ minutes: props.minutes, seconds: props.seconds }}
			>
				{props.children}
			</Localized>
		);
	}
	return (
		<Localized id="chat-send-placeholder" attrs={{ placeholder: true }}>
			{props.children}
		</Localized>
	);
};

export default SendMessage;
