import { Localized } from "@fluent/react";
import { LvtLink, LvtText } from "components/lvtComponents/components";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { currentMapPath } from "constants/paths";
import { useLocalizedContent } from "hooks/localizedContent";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import slugify from 'slugify';
import './venueMaps.scss';
const VenueMaps = () => {
    const { maps = [] } = useSelector(state => state.config);
    const { slug } = useParams();
    if (maps.length === 1) {
        return <Redirect to={currentMapPath(slug, slugify(maps[0].name))} />
    }
    return <NavigationWrapper>
        <div className="maps">
            <h1><Localized id="venuemaps-heading">Venue maps</Localized></h1>
            {maps.map(map => <MapLink {...map} slug={slug} />)}
        </div>

    </NavigationWrapper>
}

const MapLink = ({ name, description, title, slug }) => {
    const titleText = useLocalizedContent(title)
    const descriptionText = useLocalizedContent(description);

    return <LvtLink to={currentMapPath(slug, slugify(name))} className="map-link">
        <LvtText set="h3" className="map-link-head">{titleText}</LvtText>
        <LvtText set="small" className="map-link-topic">{descriptionText}</LvtText>
    </LvtLink>
}

export default VenueMaps;