/** @format */

import React, { useEffect, useState } from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import ChatFeed from "./chatFeed";
import SendMessage from "./sendMessage";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import "./chat.scss";
import PasswordPrompt from "./passwordPrompt";

const Chat = props => {
	const { room } = useParams();
	const dispatch = useDispatch();
	const { joined_rooms, online } = useSelector(state => state.connection);
	const chatrooms = useSelector(state => state.chatrooms);
	const [passwordPromptView, setPasswordPromptView] = useState(false);

	useEffect(() => {
		const roomData = chatrooms.find(c => c.room_id === room);

		if (joined_rooms.includes(room) && online) {
			if (passwordPromptView) {
				setPasswordPromptView(false);
			}
			if (roomData) {
				dispatch({ type: "ACTIVE_CHAT::SET_ACTIVE_ROOM", payload: roomData });
				dispatch({
					type: "WEBSOCKET::OUT::REQUEST_CHAT_HISTORY",
					payload: { room_id: roomData.room_id, skip: 0 },
					meta: "SEND_SIGNAL",
				});
			}
		} else {
			// Show password prompt
			if (roomData) {
				if (roomData.password_protected) {
					setPasswordPromptView(true);
				} else {
					dispatch({
						type: "WEBSOCKET::OUT::REQUEST_JOIN_ROOM",
						payload: { room_id: room },
						meta: "SEND_SIGNAL",
					});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [online, chatrooms.length, joined_rooms.length]);

	return passwordPromptView ? (
		<NavigationWrapper className="chat-password-prompt">
			<PasswordPrompt />
		</NavigationWrapper>
	) : (
		<NavigationWrapper className="chat">
			<ChatFeed />
			<SendMessage />
		</NavigationWrapper>
	);
};

export default Chat;
