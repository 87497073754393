import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useSelector } from "react-redux";
import { MapInteractionCSS } from 'react-map-interaction';

import slugify from 'slugify';

import './venuemap.scss';
import { useParams } from "react-router";
import { useLocalizedContent } from "hooks/localizedContent";

const Venuemap = () => {
    const { map } = useParams();
    const { maps = [] } = useSelector(state => state.config);
    const currentMap = maps.find(m => slugify(m.name) === slugify(map));

    const title = useLocalizedContent(currentMap?.title);
    const description = useLocalizedContent(currentMap?.description)


    if (!currentMap) {
        return <NavigationWrapper className="venuemap">
        </NavigationWrapper>
    }

    return <NavigationWrapper className="venuemap">
        <h1 className="map-name">{title}</h1>
        <p className="map-description">{description}</p>
        <MapInteractionCSS>
            <img src={currentMap.image} className="map" alt="venue-map" />
        </MapInteractionCSS>
    </NavigationWrapper>
}

export default Venuemap;