/** @format */

import { getCookie } from "utils/cookies";
import * as requests from "./socketRequests";
import { wsOut, wsIn } from "constants/actionTypes";

import SignallingClient from "utils/signallingClient";
import {
	handleAttendeesRequested,
	handleReconnected,
	handleRoomJoined,
	handleClientTokenRequested,
	requestChatHistory,
	requestClientToken,
	requestJoinRoom,
	requestPollResults,
	handleIncomingVote,
	handleMyIncomingVote,
} from "./actions";
import { getUserHeldMessages } from "utils/websocket";


async function chatServiceNotify(msg) {
	// console.log('chatServiceNotify', msg);
	return await SignallingClient.ServiceInvoker.notifyService("chat", "push", msg);
}

export async function chatServiceInvoke(method, msg) {
	return await SignallingClient.ServiceInvoker.invokeService("chat", method, msg);
}

export default function websocketMiddleware({ dispatch, getState }) {

	const onOpen = (dispatch, allow_reconnect) => (event) => {
		dispatch({ type: "WEBSOCKET::CONNECT" });

		const { channel_name, chat_user_id } = getState().connection;

		if (channel_name && chat_user_id && allow_reconnect) {
			let payload = {
				channel_name: channel_name,
				user_id: chat_user_id,
				api_token: getCookie(process.env.REACT_APP_AUTH_COOKIE),
			};
			chatServiceNotify(requests.RECONNECT(payload));
		} else {
			dispatch(requestClientToken());
		}
	};

	const onMessage = (dispatch) => (msg) => {
		let message = typeof msg === "string" ? JSON.parse(msg) : msg;
		const type = `WEBSOCKET::INCOMING_SIGNAL::${message.type.toUpperCase()}`;

		switch (type) {
			case wsIn.CLIENT_TOKEN_REQUESTED:
				dispatch(handleClientTokenRequested(message.payload));
				//dispatch({ type: "WEBSOCKET::OUT::REQUEST_JOIN_ROOM", meta: "SEND_SIGNAL" });
				break;

			case wsIn.RECONNECTED:
				dispatch(handleReconnected(message.payload));
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::SEND_MESSAGE_FAILED":
				//dispatch({ type: a.ADD_ALERT, payload: { variant: "error", type: "SEND_MESSAGE_FAILED", data: message.payload.error } });
				break;

			case wsIn.RECONNECT_FAILED:
				dispatch(requestClientToken());
				break;

			case wsIn.ATTENDEES_REQUESTED:
				dispatch(handleAttendeesRequested(message.payload));
				break;

			// ROOM LOGIC
			case wsIn.ROOM_JOINED:
				const { is_private_chat, room_id } = message.payload;
				const chatrooms = getState().chatrooms;

				const joinedRoom = chatrooms.find((ch) => ch.room_id === room_id);

				if (joinedRoom && !is_private_chat) {
					const payload = {
						...joinedRoom,
						chat_history: message.payload.chat_history,
						more: message.payload.more,
					};
					dispatch(handleRoomJoined(payload));
				}
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::JOIN_ROOM_FAILED":
				dispatch({ type: "WEBSOCKET::HANDLE_SIGNAL::ROOM_JOIN_FAILED", payload: message.payload })
				//dispatch({ type: a.WS_ROOM_JOIN_FAILED, payload: message.payload });
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::USER_JOINED_CHANNEL":
				//dispatch({ type: a.WS_USER_JOINED_CHANNEL, payload: message.payload });
				break;

			// Another user has joined a channel this client has already joined
			case "WEBSOCKET::INCOMING_SIGNAL::USER_JOINED_ROOM":
				//dispatch({ type: a.WS_USER_JOINED_ROOM, payload: message.payload });
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::JOIN_ROOM_FAILED":
				//dispatch({ type: a.WS_JOIN_ROOM_FAILED, payload: message.payload });
				break;

			// CHAT LOGIC
			case "WEBSOCKET::INCOMING_SIGNAL::MESSAGE_SENT":
				dispatch({ type: "WEBSOCKET::HANDLE_SIGNAL::MESSAGE_SENT", payload: message.payload });
				//addMessageToBatch(message.payload);
				break;

			// Response from websocket server when user has requested chat history for specific room
			case "WEBSOCKET::INCOMING_SIGNAL::CHAT_HISTORY_REQUESTED":
				dispatch({ type: "WEBSOCKET::HANDLE_SIGNAL::CHAT_HISTORY_REQUESTED", payload: message.payload });
				const { room_id: roomID } = getState().chat
				const { chat_user_id } = getState().user
				getUserHeldMessages(roomID, chat_user_id, dispatch)
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::MESSAGE_REACTION":
				dispatch({ type: "WEBSOCKET::HANDLE_SIGNAL::MESSAGE_REACTION", payload: message.payload });
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::POLLS_REQUESTED":
				dispatch({ type: "WEBSOCKET::HANDLE_SIGNAL::POLLS_REQUESTED", payload: message.payload });
				break;

			case wsIn.MY_VOTE:
				// Response for my vote
				dispatch(handleMyIncomingVote(message.payload));
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::VOTE_FOR_POLL":
				// User has voted something
				dispatch(handleIncomingVote(message.payload));
				break;

			case "WEBSOCKET::INCOMING_SIGNAL::ALL_USER_MESSAGES_DELETED": {
				dispatch({ type: "WEBSOCKET::HANDLE::ALL_USER_MESSAGES_DELETED", payload: message.payload });
				break;
			}
			case wsIn.HELD_MESSAGE_APPROVED:
			case wsIn.HELD_MESSAGE_DECLINED:
			case "WEBSOCKET::INCOMING_SIGNAL::MESSAGE_DELETED": {
				dispatch({ type: "WEBSOCKET::HANDLE::MESSAGE_DELETED", payload: message.payload });
				break;
			}

			case "WEBSOCKET::INCOMING_SIGNAL::USER_SILENCED": {
				dispatch({ type: "WEBSOCKET::HANDLE::USER_SILENCED", payload: message.payload });
				break;
			}

			case wsIn.MESSAGE_HELD: {
				let msg = message.payload
				msg.held = true
				dispatch({ type: "WEBSOCKET::HANDLE_SIGNAL::MESSAGE_SENT", payload: msg });
				break;
			}

			default:
				break;
		}
	};

	const onClose = (dispatch) => (event) => {
		dispatch({ type: "WEBSOCKET::DISCONNECT" });
	};

	const onError = (dispatch) => (event) => {
		console.error(event);
	};

	return (next) => (action) => {
		if (action.meta === "SEND_SIGNAL") {
			switch (action.type) {
				case wsOut.INIT_WEBSOCKET:
					const { event } = getState().connection;
					const allow_reconnect = event !== null;

					SignallingClient.on("open", onOpen(dispatch, allow_reconnect));
					SignallingClient.on("close", onClose(dispatch));
					SignallingClient.on("error", onError(dispatch));
					SignallingClient.exposeRPCMethod("chatCallback", onMessage(dispatch), { replace: true });
					SignallingClient.init({
						signallingURL: `${process.env.REACT_APP_WEBSOCKET_URL}`,
						token: getCookie(process.env.REACT_APP_AUTH_COOKIE),
					});
					break;

				case wsOut.RECONNECT: {
					// if (SignallingClient.isConnected) {
					//     const { channel_name } = getState().connection;
					//     let payload = { channel_name: getState().websocketUser.channel_name, user_id: getState().websocketUser.user_id };
					//     const api_token = getCookie(AUTH_COOKIE);
					//     payload["api_token"] = api_token;
					//     SignallingClient.updateToken(api_token);
					//     chatServiceNotify(RECONNECT(payload));
					// }
					break;
				}

				case wsOut.REQUEST_CLIENT_TOKEN: {
					if (SignallingClient.isConnected) {
						const apiToken = getCookie(process.env.REACT_APP_AUTH_COOKIE);
						const payload = {
							api_token: apiToken,
							event: getState().connection.event,
						};

						SignallingClient.updateToken(apiToken);
						chatServiceNotify(requests.REQUEST_CLIENT_TOKEN(payload));
					} else {
						setTimeout(() => dispatch(requestClientToken), 500);
					}
					break;
				}

				case wsOut.REQUEST_CHAT_HISTORY:
					if (SignallingClient.isConnected) {
						chatServiceNotify(requests.REQUEST_CHAT_HISTORY(action.payload));
					} else {
						setTimeout(() => dispatch(requestChatHistory(action.payload)), 2000);
					}
					break;

				case wsOut.REQUEST_JOIN_ROOM:
					if (SignallingClient.isConnected) {
						chatServiceNotify(
							requests.REQUEST_JOIN_ROOM({
								...action.payload,
								user_id: getState().connection.chat_user_id,
							})
						);
					} else {
						setTimeout(() => {
							dispatch(requestJoinRoom(action.payload));
						}, 1000);
					}
					break;

				case wsOut.SEND_MESSAGE:
					chatServiceNotify(requests.SEND_MESSAGE(action.payload));
					break;

				case wsOut.SEND_PRIVATE_MESSAGE:
					//chatServiceNotify(SEND_PRIVATE_MESSAGE(action.payload));
					break;

				case wsOut.REQUEST_ATTENDEES:
					//chatServiceNotify(REQUEST_ATTENDEES());
					break;

				case wsOut.REACT_TO_MESSAGE:
					chatServiceNotify(requests.REACT_TO_MESSAGE(action.payload));
					break;

				// POLLS
				case wsOut.WANT_TO_VOTE_FOR_POLL:
					chatServiceNotify(requests.VOTE_FOR_POLL(action.payload));
					break;

				case wsOut.REQUEST_POLL_RESULTS:
					if (SignallingClient.isConnected) {
						chatServiceNotify(requests.REQUEST_POLL_RESULTS());
					} else {
						setTimeout(() => {
							dispatch(requestPollResults());
						}, 1000);
					}
					break;

				// MODERATOR THINGS
				case wsOut.DELETE_MESSAGE:
					// if (getState().websocketUser.is_moderator) {
					//     chatServiceNotify(DELETE_MESSAGE(action.payload));
					// }
					break;

				case wsOut.DELETE_ALL_USER_MESSAGES:
					// if (getState().websocketUser.is_moderator) {
					//     chatServiceNotify(DELETE_ALL_USER_MESSAGES(action.payload));
					// }
					break;

				case wsOut.SILENCE_USER:
					// if (getState().websocketUser.is_moderator) {
					//     chatServiceNotify(SILENCE_USER(action.payload));
					// }
					break;

				default:
					break;
			}
		}

		return next(action);
	};
}
