import moment from "moment";
import { genericActions as G, wsOut } from "constants/actionTypes";

const getSnowplowCtx = (state) => {
    const { event } = state;
    const role = "guest"; // TODO figure out user role if/when non-guests are allowed to login
    return [{
        schema: "iglu:io.liveto/event_app/jsonschema/1-0-0",
        data: {
            event: event.slug,
            private: event.is_private,
            active: moment().isBetween(event.start_time, event.end_time),
            role: role
        }
    }];
}

export default function analyticsMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        if (!window.sp) return next(action);

        switch (action.type) {
            // initialize snowplow when user logs in
            // TODO cookie consent? we already use them to login though
            case "USER::SET_USER": {
                if (window.sp) {
                    const sp = window.sp;
                    sp("newTracker", "e", process.env.REACT_APP_COLLECTOR_DOMAIN, {
                        appId: "event-app",
                        platform: "web",
                        contexts: { webPage: true },
                        anonymousTracking: false,
                        eventMethod: "post",
                        stateStorageStrategy: "cookieAndLocalStorage",
                        bufferSize: 1,
                        forceSecureTracker: true,
                    });
                    sp("enableActivityTracking:e", 30, 30);
                    sp("setUserId:e", action.payload.chat_user_id);
                    window.sp_tracking = true;
                }
                break;
            }

            // send page view for each navigate into different view
            case G.NAVIGATE: {
                if (window.sp_tracking) {
                    // When navigating to chats list, skip page view if only one chatroom is enabled.
                    // Client navigates instantly to the only possible room so this would be useless.
                    const state = getState();
                    if (action.payload.pathname === `/${state.event.slug}/chat` && state.chatrooms.length === 1)
                        break;

                    window.sp("trackPageView:e", null, getSnowplowCtx(state));
                }
                break;
            }
            
            // track sent messages to public channels
            // TODO: i don't think private messages are supported in event app?
            case wsOut.SEND_MESSAGE: {
                if (window.sp_tracking) {
                    window.sp("trackStructEvent:e",
                        "chat",                 // category
                        "message-sent",         // action
                        action.payload.room_id, // label
                        "",                     // property
                        String(action.payload.message.length), // value (msg length)
                        getSnowplowCtx(getState())
                    );
                }
                break;
            }

            case wsOut.REACT_TO_MESSAGE: {
                if (window.sp_tracking) {
                    window.sp("trackStructEvent:e",
                        "chat",                     // category
                        "reaction",                 // action
                        action.payload.room_id,     // label
                        action.payload.reaction,    // property
                        "",                         // value (TODO: +1/-1 depending on if reaction was added or removed?)
                        getSnowplowCtx(getState())
                    );
                }
                break;
            }

            case wsOut.WANT_TO_VOTE_FOR_POLL: {
                if (window.sp_tracking) {
                    window.sp("trackStructEvent:e",
                        "poll",                     // category
                        "vote",                     // action
                        action.payload.poll_id,     // label
                        "",                         // property
                        "",                         // value
                        getSnowplowCtx(getState())
                    );
                }
                break;
            }


            default: break;
        }
        return next(action);
    }
}