/** @format */

import { wsHandle } from "constants/actionTypes";

const initialState = {
	polls: [],
	loading: false,
};

const pollsModuleReducer = (state = initialState, action) => {
	switch (action.type) {
		case "WEBSOCKET::INIT": {
			return {
				...state,
				loading: true,
				polls: action.payload.polls.filter(p => {
					const { show_in = [] } = p;
					return show_in.includes("event_app");
				}),
			};
		}

		case "WEBSOCKET::HANDLE_SIGNAL::POLLS_REQUESTED": {
			return { ...state, loading: false, polls: action.payload };
		}
		case wsHandle.HANDLE_MY_VOTE: {
			const newPolls = [...state.polls];
			for (let poll of newPolls) {
				let options = action.payload.find(incoming_data => incoming_data.id === poll.id).options;
				if (options) {
					poll.options = options;
				}
			}
			return { ...state, polls: newPolls };
		}

		case wsHandle.HANDLE_INCOMING_VOTE: {
			// TODO: add userID to this for own vote logic too?
			let newPolls = [...state.polls];
			const poll = newPolls.find(poll => poll.id === action.payload.poll_id);
			if (poll) {
				const option = poll.options.find(option => option.id === action.payload.answer_id);
				if (option) {
					if (!option.votes) option.votes = 0;
					option.votes++;
				}
			}
			return { ...state, polls: newPolls };
		}

		default:
			return state;
	}
};

export default pollsModuleReducer;
