/** @format */

import React from "react";

import { Icon } from "react-icons-kit";
import { copy } from "react-icons-kit/fa/copy";
import { times } from "react-icons-kit/fa/times";
import { infoCircle } from "react-icons-kit/fa/infoCircle";
import { chevronDown } from "react-icons-kit/fa/chevronDown";
import { edit } from "react-icons-kit/fa/edit";
import { plus } from "react-icons-kit/fa/plus";
import { arrowsAlt } from "react-icons-kit/fa/arrowsAlt";
import { frownO } from "react-icons-kit/fa/frownO";
import { ban } from "react-icons-kit/fa/ban";
import { trash } from "react-icons-kit/fa/trash";
import { image } from "react-icons-kit/fa/image";
import { thList } from "react-icons-kit/fa/thList";
import { download } from "react-icons-kit/fa/download";
import { sliders } from "react-icons-kit/fa/sliders";
import { calendar } from "react-icons-kit/fa/calendar";
import { globe } from "react-icons-kit/fa/globe";
import { videoCamera } from "react-icons-kit/fa/videoCamera";
import { ticket } from "react-icons-kit/fa/ticket";
import { addressCard } from "react-icons-kit/fa/addressCard";
import { desktop } from "react-icons-kit/fa/desktop";
import { bullhorn } from "react-icons-kit/fa/bullhorn";
import { lineChart } from "react-icons-kit/fa/lineChart";
import { areaChart } from "react-icons-kit/fa/areaChart";
import { euro } from "react-icons-kit/fa/euro";
import { listOl } from "react-icons-kit/fa/listOl";
import { users } from "react-icons-kit/fa/users";
import { qrcode } from "react-icons-kit/fa/qrcode";
import { shoppingCart } from "react-icons-kit/fa/shoppingCart";
import { unlock } from "react-icons-kit/fa/unlock";
import { clipboard } from "react-icons-kit/fa/clipboard";
import { barChart } from "react-icons-kit/fa/barChart";
import { eye } from "react-icons-kit/fa/eye";
import { save } from "react-icons-kit/fa/save";
import { ic_border_all } from "react-icons-kit/md/ic_border_all";
import { ic_store_mall_directory } from "react-icons-kit/md/ic_store_mall_directory";
import { ic_history } from "react-icons-kit/md/ic_history";
import { filesO } from "react-icons-kit/fa/filesO";
import { tablet } from "react-icons-kit/fa/tablet";
import { list } from "react-icons-kit/fa/list";
import { print } from "react-icons-kit/fa/print";
import { buildingO } from "react-icons-kit/fa/buildingO";
import { link } from "react-icons-kit/fa/link";
import { random } from "react-icons-kit/fa/random";
import { check } from "react-icons-kit/fa/check";
import { cogs } from "react-icons-kit/fa/cogs";
import { paintBrush } from "react-icons-kit/fa/paintBrush";
import { clockO } from "react-icons-kit/fa/clockO";
import { cog } from "react-icons-kit/fa/cog";
import { signOut } from "react-icons-kit/fa/signOut";
import { userCircleO } from "react-icons-kit/fa/userCircleO";
import { userO } from "react-icons-kit/fa/userO";
import { lock } from "react-icons-kit/fa/lock";
import { bars } from "react-icons-kit/fa/bars";
import { caretUp } from "react-icons-kit/fa/caretUp";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { unsorted } from "react-icons-kit/fa/unsorted";
import { ic_receipt } from "react-icons-kit/md/ic_receipt";
import { checkSquare } from "react-icons-kit/fa/checkSquare";
import { quoteRight } from "react-icons-kit/fa/quoteRight";
import { comments } from "react-icons-kit/fa/comments";
import { androidContacts } from "react-icons-kit/ionicons/androidContacts";
import { home } from "react-icons-kit/fa/home";
import { info } from "react-icons-kit/fa/info";
import { chevronUp } from "react-icons-kit/fa/chevronUp";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import { chevronLeft } from "react-icons-kit/fa/chevronLeft";
import { ic_location_city } from "react-icons-kit/md/ic_location_city";
import { angleDoubleRight } from "react-icons-kit/fa/angleDoubleRight";
import { ic_pie_chart } from "react-icons-kit/md/ic_pie_chart";
import { ic_call_split } from "react-icons-kit/md/ic_call_split";
import { tasklist } from "react-icons-kit/oct/tasklist";
import { userPlus } from "react-icons-kit/feather/userPlus";
import { box } from "react-icons-kit/feather/box";
import { linkedinSquare } from "react-icons-kit/fa/linkedinSquare";
import { twitterSquare } from "react-icons-kit/fa/twitterSquare";
import { images } from "react-icons-kit/icomoon/images";
import { searchPlus } from "react-icons-kit/fa/searchPlus";
import { filter } from "react-icons-kit/fa/filter";
import { sortAmountDesc } from "react-icons-kit/fa/sortAmountDesc";
import { ic_more_vert } from "react-icons-kit/md/ic_more_vert";
import { ic_more_horiz } from "react-icons-kit/md/ic_more_horiz";
import { ic_person_add } from "react-icons-kit/md/ic_person_add";
import { box as entypobox } from "react-icons-kit/entypo/box";
import { pictureO } from "react-icons-kit/fa/pictureO";
import { androidColorPalette } from "react-icons-kit/ionicons/androidColorPalette";
import { ribbon } from "react-icons-kit/entypo/ribbon";
import { documentIcon } from "react-icons-kit/ikons/documentIcon";
import { copy_1 } from "react-icons-kit/ikons/copy_1";
import { thLarge } from "react-icons-kit/typicons/thLarge";
import { ic_call_to_action } from "react-icons-kit/md/ic_call_to_action";
import { ic_open_with } from "react-icons-kit/md/ic_open_with";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import { navicon } from "react-icons-kit/fa/navicon";
import { star } from "react-icons-kit/fa/star";
import { ic_store } from "react-icons-kit/md/ic_store";
import { organization } from "react-icons-kit/oct/organization";
import { ic_settings } from "react-icons-kit/md/ic_settings";
import { lightbulbO } from "react-icons-kit/fa/lightbulbO";
import { ic_volume_up } from "react-icons-kit/md/ic_volume_up";
import { ic_volume_off } from "react-icons-kit/md/ic_volume_off";
import { send } from "react-icons-kit/fa/send";
import { mapO } from "react-icons-kit/fa/mapO";
import { longArrowLeft } from "react-icons-kit/fa/longArrowLeft";
import { reply } from "react-icons-kit/fa/reply";

export const IconReply = props => <Icon icon={reply} {...props} />;
export const IconArrowLeft = props => <Icon icon={longArrowLeft} {...props} />;
export const IconMap = props => <Icon icon={mapO} {...props} />;
export const IconSend = props => <Icon icon={send} {...props} />;

export const IconUnmute = props => <Icon icon={ic_volume_up} {...props} />;
export const IconMute = props => <Icon icon={ic_volume_off} {...props} />;
export const IconLightBulb = props => <Icon icon={lightbulbO} {...props} />;
export const IconSettings = props => <Icon icon={ic_settings} {...props} />;
export const IconOrganizer = props => <Icon icon={organization} {...props} />;
export const IconDropshop = props => <Icon icon={ic_store} {...props} />;
export const IconStar = props => <Icon icon={star} {...props} />;
export const IconHamburger = props => <Icon icon={navicon} {...props} />;
export const IconMaterials = props => <Icon icon={entypobox} {...props} />;
export const IconAddUser = props => <Icon icon={ic_person_add} {...props} />;
export const IconDotsVertical = props => <Icon icon={ic_more_vert} {...props} />;
export const IconDotsHorizontal = props => <Icon icon={ic_more_horiz} {...props} />;

export const IconDelete = props => <Icon icon={ic_delete} {...props} />;
export const IconOpenWith = props => <Icon icon={ic_open_with} {...props} />;
export const IconCallToAction = props => <Icon icon={ic_call_to_action} {...props} />;
export const IconThLarge = props => <Icon icon={thLarge} {...props} />;
export const IconCopy1 = props => <Icon icon={copy_1} {...props} />;
export const IconDocument = props => <Icon icon={documentIcon} {...props} />;
export const IconRibbon = props => <Icon icon={ribbon} {...props} />;
export const IconAndroidColorPalette = props => <Icon icon={androidColorPalette} {...props} />;
export const IconPictureO = props => <Icon icon={pictureO} {...props} />;
export const IconSorting = props => <Icon icon={sortAmountDesc} {...props} />;
export const IconFilter = props => <Icon icon={filter} {...props} />;
export const IconZoom = props => <Icon icon={searchPlus} {...props} />;
export const IconImages = props => <Icon icon={images} {...props} />;
export const IconTwitter = props => <Icon icon={twitterSquare} {...props} />;
export const IconLinkedIn = props => <Icon icon={linkedinSquare} {...props} />;
export const IconBox = props => <Icon icon={box} {...props} />;
export const IconUserPlus = props => <Icon icon={userPlus} {...props} />;
export const IconTasklist = props => <Icon icon={tasklist} {...props} />;
export const IconSplit = props => <Icon icon={ic_call_split} {...props} />;
export const IconPieChart = props => <Icon icon={ic_pie_chart} {...props} />;
export const IconAngleDoubleRight = props => <Icon icon={angleDoubleRight} {...props} />;
export const IconCity = props => <Icon icon={ic_location_city} {...props} />;
export const IconInfo = props => <Icon icon={info} {...props} />;
export const IconHome = props => <Icon icon={home} {...props} />;
export const IconComments = props => <Icon icon={comments} {...props} />;
export const IconAndroidContacts = props => <Icon icon={androidContacts} {...props} />;
export const IconQuoteRight = props => <Icon icon={quoteRight} {...props} />;
export const IconCheckSquare = props => <Icon icon={checkSquare} {...props} />;
export const IconReceipt = props => <Icon icon={ic_receipt} {...props} />;
export const IconCaretUp = props => <Icon icon={caretUp} {...props} />;
export const IconCaretDown = props => <Icon icon={caretDown} {...props} />;
export const IconUnsorted = props => <Icon icon={unsorted} {...props} />;
export const IconLock = props => <Icon icon={lock} {...props} />;
export const IconBars = props => <Icon icon={bars} {...props} />;
export const IconUser = props => <Icon icon={userO} {...props} />;
export const IconUserCircle = props => <Icon icon={userCircleO} {...props} />;
export const IconSignOut = props => <Icon icon={signOut} {...props} />;
export const IconCog = props => <Icon icon={cog} {...props} />;
export const IconClock = props => <Icon icon={clockO} {...props} />;
export const IconPaintBrush = props => <Icon icon={paintBrush} {...props} />;
export const IconCogs = props => <Icon icon={cogs} {...props} />;
export const IconCheck = props => <Icon icon={check} {...props} />;
export const IconRandom = props => <Icon icon={random} {...props} />;
export const IconLink = props => <Icon icon={link} {...props} />;
export const IconBuilding = props => <Icon icon={buildingO} {...props} />;
export const IconPrint = props => <Icon icon={print} {...props} />;
export const IconList = props => <Icon icon={list} {...props} />;
export const IconTablet = props => <Icon icon={tablet} {...props} />;
export const IconFiles = props => <Icon icon={filesO} {...props} />;
export const IconHistory = props => <Icon icon={ic_history} {...props} />;
export const IconStore = props => <Icon icon={ic_store_mall_directory} {...props} />;
export const IconBorders = props => <Icon icon={ic_border_all} {...props} />;
export const IconSave = props => <Icon icon={save} {...props} />;
export const IconEye = props => <Icon icon={eye} {...props} />;
export const IconBarChart = props => <Icon icon={barChart} {...props} />;
export const IconClipboard = props => <Icon icon={clipboard} {...props} />;
export const IconUnlock = props => <Icon icon={unlock} {...props} />;
export const IconSliders = props => <Icon icon={sliders} {...props} />;
export const IconCalendar = props => <Icon icon={calendar} {...props} />;
export const IconGlobe = props => <Icon icon={globe} {...props} />;
export const IconVideoCamera = props => <Icon icon={videoCamera} {...props} />;
export const IconTicket = props => <Icon icon={ticket} {...props} />;
export const IconAddressCard = props => <Icon icon={addressCard} {...props} />;
export const IconDesktop = props => <Icon icon={desktop} {...props} />;
export const IconBullhorn = props => <Icon icon={bullhorn} {...props} />;
export const IconLineChart = props => <Icon icon={lineChart} {...props} />;
export const IconAreaChart = props => <Icon icon={areaChart} {...props} />;
export const IconEuro = props => <Icon icon={euro} {...props} />;
export const IconListOl = props => <Icon icon={listOl} {...props} />;
export const IconUsers = props => <Icon icon={users} {...props} />;
export const IconQrCode = props => <Icon icon={qrcode} {...props} />;
export const IconShoppingCart = props => <Icon icon={shoppingCart} {...props} />;
export const IconDownload = props => <Icon icon={download} {...props} />;
export const IconThList = props => <Icon icon={thList} {...props} />;
export const IconImage = props => <Icon icon={image} {...props} />;
export const IconTrash = props => <Icon icon={trash} {...props} />;
export const IconBan = props => <Icon icon={ban} {...props} />;
export const IconFrown = props => <Icon icon={frownO} {...props} />;
export const IconArrowsAlt = props => <Icon icon={arrowsAlt} {...props} />;
export const IconPlus = props => <Icon icon={plus} {...props} />;
export const IconEdit = props => <Icon icon={edit} {...props} />;
export const IconChevronDown = props => <Icon icon={chevronDown} {...props} />;
export const IconChevronUp = props => <Icon icon={chevronUp} {...props} />;
export const IconChevronRight = props => <Icon icon={chevronRight} {...props} />;
export const IconChevronLeft = props => <Icon icon={chevronLeft} {...props} />;

export const IconInfoCircle = props => <Icon icon={infoCircle} {...props} />;
export const IconCopy = props => <Icon icon={copy} {...props} />;
export const IconTimes = props => <Icon icon={times} {...props} />;
