import { Localized } from "@fluent/react";
import { useMonitorSize } from "hooks/monitorWidth";
import QRCode from "react-qr-code";
import React, { useRef } from "react";
import "./join.scss";

const Join = props => {
    const { type, children, qrCode } = props;
    const containerRef = useRef();
    const [width, height] = useMonitorSize(containerRef);

    return (
		<div id="join" ref={containerRef}>
			{children}
			<QRCode
				value={qrCode}
				style={{ height: "auto", minHeight: height / 4, width: "100%", maxWidth: width / 2 }}
			/>
			{type === "chat" ? <ChatInstructions /> : <PollInstructions />}
		</div>
	);
};

const ChatInstructions = props => {
    return <div className="instructions">
        <h3 className="instruction-heading">
            <Localized id="chatfeed-instructions-heading" />
        </h3>
        <ol>
            <li>
                <p className="instruction-step">
                    <Localized id="chatfeed-instructions-step1" />
                </p>
            </li>
            <li>
                <p className="instruction-step">
                    <Localized id="chatfeed-instructions-step2" />
                </p>
            </li>
            <li>
                <p className="instruction-step">
                    <Localized id="chatfeed-instructions-step3" />
                </p>
            </li>
        </ol>
    </div>
}

const PollInstructions = props => {
    return <div className="instructions">
        <h3 className="instruction-heading">
            <Localized id="pollresults-instructions-heading" />
        </h3>
        <ol>
            <li>
                <p className="instruction-step">
                    <Localized id="pollresults-instructions-step1" />
                </p>
            </li>
            <li>
                <p className="instruction-step">
                    <Localized id="pollresults-instructions-step2" />
                </p>
            </li>
            <li>
                <p className="instruction-step">
                    <Localized id="pollresults-instructions-step3" />
                </p>
            </li>
        </ol>
    </div>
}

export default Join;









