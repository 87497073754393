/** @format */

import { Localized } from "@fluent/react";
import { defineHeaders } from "utils/api";
import React, { useEffect, useState } from "react";
import useFetch from "use-http";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

export const useApiPost = props => {
	const {
		headers = null,
		apipath = null,
		responseType = "json",
		cachePolicy = "no-cache",
		rootUrl = process.env.REACT_APP_BACKEND_URL,
	} = props;

	const { response, post, abort } = useFetch(rootUrl, {
		responseType: responseType,
		cachePolicy: cachePolicy,
		headers: headers ? headers : defineHeaders(),
	});
	const { state, resolve, start, resetState } = useApiStatuses(response, apipath, "post");

	async function call(endpoint, body) {
		try {
			start();
			const data = await post(endpoint, body);
			const ok = resolve(data);
			return [data, ok];
		} catch {
			console.error(`Failed to POST ${endpoint}`);
			return [null, false];
		}
	}

	const abortCall = () => abort();
	useEffect(() => {
		return () => abortCall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [call, state, resetState];
};

export const useApiGet = props => {
	const {
		headers = null,
		apipath = null,
		responseType = "json",
		rootUrl = process.env.REACT_APP_BACKEND_URL,
	} = props;

	const { response, get, abort } = useFetch(rootUrl, {
		responseType: responseType,
		headers: headers ? headers : defineHeaders(),
	});

	async function call(endpoint) {
		try {
			start();
			const data = await get(endpoint);
			const ok = resolve(data);
			window.parent.postMessage({ message: "Test", data: data });

			return [data, ok];
		} catch {
			console.error(`Failed to GET ${endpoint}`);
			return [null, false];
		}
	}
	const { state, resolve, start, resetState } = useApiStatuses(response, apipath, "get");

	const abortCall = () => abort();
	useEffect(() => {
		return () => abortCall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return [call, state, resetState];
};

export const useApiPatch = props => {
	const {
		headers = null,
		apipath = null,
		responseType = "json",
		cachePolicy = "no-cache",
		rootUrl = process.env.REACT_APP_BACKEND_URL,
	} = props;

	const { response, patch, abort } = useFetch(rootUrl, {
		responseType: responseType,
		cachePolicy: cachePolicy,
		headers: headers ? headers : defineHeaders(true),
	});

	async function call(endpoint, body) {
		try {
			start();
			const data = await patch(endpoint, body);
			const ok = resolve(data);
			return [data, ok];
		} catch {
			console.error(`Failed to GET ${endpoint}`);
			return [null, false];
		}
	}
	const { state, resolve, start, resetState } = useApiStatuses(response, apipath, "patch");

	const abortCall = () => abort();
	useEffect(() => {
		return () => abortCall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return [call, state, resetState];
};

export const useAccessKey = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	return params.get("access_key");
};

export const useApiStatuses = (response, endpoint = null, method = null) => {
	const initialState = {
		loading: false,
		ok: null,
		status: null,
		error: null,
	};
	const [state, setState] = useState(initialState);

	function resetState() {
		setState(initialState);
	}
	function start() {
		setState({
			...state,
			loading: true,
		});
	}

	// Handle status changes, and errors
	function resolve(errorData = "No error defined in frontend") {
		let error = null;
		if (!response.ok) {
			switch (response.status) {
				case 400:
					error = showError(response.status);
					break;
				case 401:
					error = showError(response.status);
					break;
				case 402:
					error = showError(response.status);
					break;
				case 403:
					error = showError(response.status);
					break;
				case 404:
					error = showError(response.status);
					break;
				default:
					error = `Something went wrong`;
					break;
			}
			console.error(`${response.status} - ${JSON.stringify(errorData)}`);
		}
		setState({
			...state,
			error: error,
			status: response.status,
			ok: response.ok,
			loading: false,
		});
		return response.ok;
	}

	function showError(status) {
		if (endpoint && method && endPointErrors[endpoint][method][status]) {
			return endPointErrors[endpoint][method][status];
		}
		return <Localized id={`apierror-${status}`} />;
	}

	return { state, resolve, start, resetState };
};

const endPointErrors = {
	"/api/v1/events/": {
		get: {
			404: "Tapahtumaa ei löytynyt",
		},
	},
	"/api/v1/users/me/": {
		get: {},
	},
	"/api/v1/chat/": {
		get: {
			404: "Ei websocket",
		},
	},
};
