/** @format */

function guessLanguage() {
	let userLanguage = "en";
	let lang =
		(navigator.languages && navigator.languages[0]) || // Chrome / Firefox
		navigator.language || // All browsers
		navigator.userLanguage; // IE <= 10
	if (lang === "fi-FI") {
		userLanguage = "fi";
	}

	return userLanguage;
}


const initialState = {
	language: guessLanguage(), // ? en / fi
	authType: null, // ? Guest / Registered
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case "USER::SET_USER":
			return { ...state, ...action.payload };
		case "LOGOUT":
			return initialState;

		case "USER::CHANGE_LANGUAGE":
			return { ...state, language: action.payload };

		default: return state;
	}
};

export default userReducer;
